import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card } from "antd";
import Summary from "./Summary";
import Dashboard from "../../index";
import PaypalCheckout from "../Paypal/checkout";

// actions
import { completeTransaction } from "../../../../actions/payment/endPayment";
import { preloadPayPalOrder } from "../../../../actions/payment/paypalOrder";

// hooks
import { useCompleteJoinBoard } from "../../../../hooks/payment";
import { useAuth } from "../../../../hooks/context";

// helpers
import storage from "../../../../helpers";

const { getPreload, clearCart } = storage;

const CheckoutForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [paypalError, setPaypalError] = useState();
  const [paypalCancel, setPaypalCancel] = useState();
  const [paypalLoading, setPaypalLoading] = useState(false);
  const { user } = useAuth();

  const { message, error, pending } = useSelector(
    (state) => state?.paypalOrder
  );

  useCompleteJoinBoard(message);

  const handleCancel = () => {
    clearCart();
    history.push("/preload-gift");
  };

  const onErrorClose = () => setPaypalError();
  const onCancelClose = () => setPaypalCancel();

  const handlePayPalError = () => {
    setPaypalLoading(false);
    setPaypalError("Money not transferred to our account, please try again");
  };

  const handlePayPalCancel = () => {
    setPaypalLoading(false);
    paypalCancel("The transaction was cancelled");
  };

  const handleApprove = (id) => {
    setPaypalLoading(true);
    dispatch(preloadPayPalOrder(id));
  };

  const handlePending = () => setPaypalLoading(true);

  return (
    <div className="preload-payment">
      <h3 className="element-header">Billing Details</h3>
      <Card className="gift-payment-container" bordered={false}>
        <PaypalCheckout
          amount={storage.getPreload()}
          user={user}
          onError={handlePayPalError}
          onCancel={handlePayPalCancel}
          handleApprove={handleApprove}
          handlePending={handlePending}
        />
      </Card>
      <div className="flex flex-jc-sb gift-payment-checkout-btn">
        <Button
          onClick={handleCancel}
          type="ghost"
          className="btn-outline-primary"
          icon={<CloseCircleOutlined />}
          loading={!error && (paypalLoading || pending)}
        >
          Cancel
        </Button>
      </div>
      <div className="gift-payment-errors">
        {error && <Alert message={error} type="error" closable showIcon />}
        {paypalCancel && (
          <Alert
            message={paypalCancel}
            type="info"
            closable
            showIcon
            onClose={onCancelClose}
          />
        )}
        {paypalError && (
          <Alert
            message={paypalError}
            type="error"
            closable
            showIcon
            onClose={onErrorClose}
          />
        )}
      </div>
    </div>
  );
};

const PreloadCheckout = () => {
  const preload = getPreload();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangeCart = () => {
    dispatch(completeTransaction());
    history.push("/preload-gift");
  };

  useEffect(() => !preload && history.push("/preload-gift"), []);

  return (
    <Dashboard>
      <div className="gift-wrapper">
        <CheckoutForm />
        <Summary preload={preload} handleChangeCart={handleChangeCart} />
      </div>
    </Dashboard>
  );
};

export default PreloadCheckout;
