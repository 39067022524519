import React from "react";
import { Modal } from "antd";
import helpers from "../../helpers";
const { reload } = helpers;

export const info = (message) => {
  Modal.info({
    content: (
      <div>
        <p className="custom-modal-message">{message}</p>
      </div>
    ),
  });
};

export const confirm = (message, ...rest) => {
  Modal.confirm({
    content: (
      <div>
        <p className="custom-modal-message">{message}</p>
      </div>
    ),
    onCancel() {},
    onOk() {
      rest[0]?.map((ops) => {
        let op = Object.keys(ops);
        if (op.length === 2) {
          ops[1](...ops[2]);
        }
        if (op.length === 3) {
          ops[1](ops[2](...ops[3]));
        }
      });
    },
  });
};

export const success = (message, history) => {
  Modal.success({
    content: (
      <div>
        <p className="custom-modal-message">{message}</p>
      </div>
    ),
    onOk() {
      reload(history);
    },
  });
};

export const error = (message, history, ...rest) => {
  Modal.error({
    content: (
      <div>
        <p className="custom-modal-message">{message}</p>
      </div>
    ),
    onOk() {
      if (rest.length) {
        const logout = rest[0];
        logout();
      }
      reload(history);
    },
  });
};

export const warning = (message) => {
  Modal.warning({
    content: (
      <div>
        <p className="custom-modal-message">{message}</p>
      </div>
    ),
  });
};
