import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Button, Card } from "antd";

// helpers
import utils from "../../../helpers";

const ACTIVE = utils.showPreload();

const Preload = ({ pending }) => {
  const history = useHistory();
  const handlePreload = () => {
    history.push("/preload-gift");
  };

  return (
    <Card style={{ padding: 0 }} className="element-box" loading={pending}>
      <h3 className="label title">Preload Gift</h3>
      <p className="element-description">Increase net pending</p>
      <Button
        type="ghost"
        className={ACTIVE && `btn-outline-primary dashboard-btn`}
        onClick={handlePreload}
        disabled={!ACTIVE}
      >
        Preload
      </Button>
    </Card>
  );
};

const Index = () =>
  ACTIVE ? (
    <Preload />
  ) : (
    <Badge.Ribbon text="Preload is disabled temporarily " color="#047bf8">
      <Preload />
    </Badge.Ribbon>
  );

Preload.propTypes = {
  pending: PropTypes.bool,
};

export default Index;
