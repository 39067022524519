import React from "react";
import PropTypes from "prop-types";

import ActiveBoards from "./ActiveBoards";
import Preload from "./Preload";
import EnterGift from "./EnterGift";
import Foundation from "./Foundation";
import Stat from "./Stat";
import Waiting from "./Waiting";
import Invites from "./Invites";

const Community = ({ invites, pending }) => (
  <>
    <div className="community-card">
      {false && <ActiveBoards pending={pending} />}
      <Preload pending={pending} />
      <EnterGift pending={pending} />
      <Waiting pending={pending} />
      {invites && <Invites invites={invites} pending={pending} />}
    </div>

    <div className="community-card">
      {false && <Waiting pending={pending} />}
    </div>

    <div className="community-card">
      <Foundation pending={pending} />
      <Stat pending={pending} />
    </div>
  </>
);

Community.propTypes = {
  invites: PropTypes.array,
  pending: PropTypes.bool,
};

export default Community;
