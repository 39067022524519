import React from "react";

// components
import { Collapse } from "antd";

const questions = [
  {
    question: "How do I register?",
    answer:
      "We have occasional signups. Write to info@ourgivingboard.com if you’d like to create an account with us.",
  },
  {
    question: "How do I add money to my account?",
    answer:
      "To preload, click on the preload button on the dashboard, enter the amount, click proceed, and choose either Paypal or Debit/Credit card.",
  },
  {
    question: "How do I send my payouts to my account balance?",
    answer:
      "Go to Payouts on the dashboard and, on the particular payout you’d like to send to account balance, click on Send To Account Balance.",
  },
  {
    question: "How do I change my password?",
    answer: "On your dashboard, click on settings, then change password.",
  },
  {
    question: "How do I reset my forgotten password?",
    answer:
      "If you can’t login because of a forgotten password, on the login screen, click on the forgot password link and enter the email you used to register. Then check your email for a link to reset your password. Once you find it, click on the link and put a new password.",
  },
  {
    question: "Can I change my Paypal link?",
    answer:
      "On your dashboard, click on settings, then click on the Change Profile tab. You will see your profile details. Click on the Update button to update your paypal link.",
  },
  {
    question: "I want to buy more than 5 gifts. Is this possible?",
    answer:
      "This is currently not possible. You can buy a maximum of 5 gifts right now.",
  },
  {
    question: "How can I withdraw money from my account?",
    answer:
      "Currently you cannot withdraw money sent from your paypal account or credit/debit card. However, if you sent your payouts to your account balance and you’d like to reverse it, go to Payouts on the dashboard and, on the particular payout you’d like to reverse, click on reverse payout.",
  },
  {
    question: "My board is not moving, is anything wrong?",
    answer:
      "No, nothing is wrong. This happens when there is not enough money circulating to keep the queues moving. If you’d like to help move the queues a bit, you can donate to foundation gifting or ask more friends to join and buy gifts.",
  },
];

const Faq = () => (
  <div className="faq">
    <h3 className="title faq-header">FAQs</h3>
    <Collapse defaultActiveKey={["1"]}>
      {questions.map((d, i) => (
        <Collapse.Panel
          header={d.question}
          key={i}
          className="site-collapse-custom-panel"
        >
          <span>{d.answer}</span>
        </Collapse.Panel>
      ))}
    </Collapse>
  </div>
);

export default Faq;
