import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//actions
import { confirmPayment } from "../actions/payment/confirmPayment";
import { completePayment } from "../actions/payment/completePayment";
import { beginPayment } from "../actions/payment/beginPayment";
import { payThroughPreload } from "../actions/payment/paymentThroughPreload";
import { preloadPayment } from "../actions/payment/preload";
import { completeTransaction } from "../actions/payment/endPayment";

// helpers
import helpers from "../helpers";
const { clearCart } = helpers;
// components
import { success, error } from "../common/Modals";

export const useBeginPayment = (board, type = "normal", ...rest) => {
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    if (event) {
      event?.preventDefault && event.preventDefault();
      type === "preload"
        ? dispatch(preloadPayment(rest[0]))
        : dispatch(beginPayment(board, type, rest));
    }
  };
  return [handleSubmit];
};

export const useConfirmPayment = (
  paymentId,
  secretIntent,
  paymentMethod,
  board,
  type
) => {
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      paymentMethod === "card" &&
        dispatch(confirmPayment(null, secretIntent, paymentId, board));
      paymentMethod === "preload" && dispatch(payThroughPreload(board, type));
    }
  };
  return [null, handleSubmit];
};

export const useCompletePayment = (payment) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (payment) {
      const {
        id: payment_method_id,
        billing_details: { email },
      } = payment;
      const customer = { customer: { email, payment_method_id } };
      dispatch(completePayment(customer));
    }
  }, [payment]);
};

export const useCompleteJoinBoard = (payment, paymentError) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (payment) {
      clearCart();
      dispatch(completeTransaction());
      success(payment?.message || payment, history);
      history.push("/home");
    } else if (paymentError) {
      dispatch(completeTransaction());
      error(paymentError?.message || paymentError);
    }
  }, [payment, paymentError]);
};

export const usePaymentInfoSaved = (complete) => {
  const history = useHistory();

  useEffect(() => {
    complete && history.push("/home");
  }, [complete]);
};
