import {
  CONFIRM_PAYMENT_PENDING,
  CONFIRM_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_FAILURE,
} from "./types";

const confirmPaymentPending = () => ({
  type: CONFIRM_PAYMENT_PENDING,
});

const confirmPaymentSuccess = (payload) => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload,
});

const confirmPaymentError = (error) => ({
  type: CONFIRM_PAYMENT_FAILURE,
  error,
});

const handleStripeResponse = (response, dispatch) => {
  response?.error
    ? dispatch(confirmPaymentError(response?.error?.message))
    : dispatch(confirmPaymentSuccess("You payment was successfuly received"));
};

export const confirmPayment = (stripe, secretIntent, paymentId) => {
  return (dispatch) => {
    dispatch(confirmPaymentPending());
    stripe
      ?.confirmCardPayment(secretIntent, {
        payment_method: paymentId,
      })
      .then((response) => handleStripeResponse(response, dispatch))
      .catch((error) => dispatch(confirmPaymentError(error)));
  };
};
