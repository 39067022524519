import utils from "../../helpers";
import {
  PAY_THROUGH_PRELOAD_PENDING,
  PAY_THROUGH_PRELOAD_SUCCESS,
  PAY_THROUGH_PRELOAD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

const payThroughPreloadPending = () => ({
  type: PAY_THROUGH_PRELOAD_PENDING,
});

const payThroughPreloadSuccess = (payload) => ({
  type: PAY_THROUGH_PRELOAD_SUCCESS,
  payload,
});

const payThroughPreloadError = (error) => ({
  type: PAY_THROUGH_PRELOAD_FAILURE,
  error,
});

export const payThroughPreload = (boards, type) => {
  const payload = {
    line_item_params: boards,
    order_params: { gift_type: "preload", source: "USER_ACCOUNT" },
  };

  if (type === "invite") payload.invited = true;

  return (dispatch) => {
    dispatch(payThroughPreloadPending());
    http()
      .post("/orders", payload)
      .then((response) => dispatch(payThroughPreloadSuccess(response?.data)))
      .catch((error) => dispatch(payThroughPreloadError(handleErrors(error))));
  };
};
