import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import Summary from "./Summary";
import Dashboard from "../../index";
import PaypalCheckoutForm from "./Paypal";
import PreloadCheckoutForm from "./Preload";

// actions
import { completeTransaction } from "../../../../actions/payment/endPayment";

import {
  getFoundation,
  getFoundationSourceType,
} from "../../../../helpers/localStorage";

const FoundationCheckout = () => {
  const foundation = getFoundation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangeCart = () => {
    dispatch(completeTransaction());
    history.push("/foundation-gift");
  };

  useEffect(() => !foundation && history.push("/foundation-gift"), []);

  const sourceType = getFoundationSourceType();
  return (
    <Dashboard>
      <div className="gift-wrapper">
        {sourceType === "Preloaded Account" ? (
          <PreloadCheckoutForm />
        ) : (
          <PaypalCheckoutForm />
        )}

        <Summary foundation={foundation} handleChangeCart={handleChangeCart} />
      </div>
    </Dashboard>
  );
};

export default FoundationCheckout;
