import utils from "../../helpers";
import { LOGOUT_PENDING, LOGOUT_SUCCESS, LOGOUT_FAILURE } from "./types";

const { clearLocalStorage } = utils;

export const logoutPending = () => ({
  type: LOGOUT_PENDING,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = () => ({
  type: LOGOUT_FAILURE,
});

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutPending());
    clearLocalStorage();
    setTimeout(() => dispatch(logoutSuccess(), 500));
  };
};
