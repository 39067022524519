// create
export const CREATE_COMMUNITY_PENDING = "CREATE_COMMUNITY_PENDING";
export const CREATE_COMMUNITY_SUCCESS = "CREATE_COMMUNITY_SUCCESS";
export const CREATE_COMMUNITY_FAILURE = "CREATE_COMMUNITY_FAILURE";

// delete
export const DELETE_COMMUNITY_PENDING = "DELETE_COMMUNITY_PENDING";
export const DELETE_COMMUNITY_SUCCESS = "DELETE_COMMUNITY_SUCCESS";
export const DELETE_COMMUNITY_FAILURE = "DELETE_COMMUNITY_FAILURE";

// update
export const UPDATE_COMMUNITY_PENDING = "UPDATE_COMMUNITY_PENDING";
export const UPDATE_COMMUNITY_SUCCESS = "UPDATE_COMMUNITY_SUCCESS";
export const UPDATE_COMMUNITY_FAILURE = "UPDATE_COMMUNITY_FAILURE";

// get
export const GET_COMMUNITIES_PENDING = "GET_COMMUNITIES_PENDING";
export const GET_COMMUNITIES_SUCCESS = "GET_COMMUNITIES_SUCCESS";
export const GET_COMMUNITIES_FAILURE = "GET_COMMUNITIES_FAILURE";
