import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Input } from "antd";

const ResetForm = ({
  onSubmitForm,
  pending,
  successMessage,
  errorMessage,
  changePageHandler,
}) => {
  const [ form ] = Form.useForm();
  return (
    <>
      <Form onFinish={onSubmitForm} form={form} name="reset">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Valid email is required!",
              type: "email",
            },
          ]}
        >
          <label>
            Email:
            <Input name="email" placeholder="user@gmail.com" />
          </label>
        </Form.Item>
        <div className="flex flex-jc-fe">
          <Form.Item>
            <Button loading={pending} type="primary" htmlType="submit">
              Reset
            </Button>
          </Form.Item>
        </div>
      </Form>

      {errorMessage && (
        <Alert message={errorMessage} type="error" closable showIcon />
      )}
      {successMessage && (
        <Alert message={successMessage} type="success" closable showIcon />
      )}

      <div className="login-form__signup flex flex-jc-c flex-ai-c">
        Login
        <Button type="link" onClick={changePageHandler} size="small">
          here
        </Button>
      </div>
    </>
  );
};

ResetForm.propTypes = {
  onSubmitForm: PropTypes.func,
  pending: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  changePageHandler: PropTypes.func,
};

export default ResetForm;
