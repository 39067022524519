import utils from "../../helpers";
import {
  PRELOAD_ORDER_FAILURE,
  PRELOAD_ORDER_SUCCESS,
  PRELOAD_ORDER_PENDING,
} from "./types";

const { http, handleErrors } = utils;

const preloadOrderPending = () => ({
  type: PRELOAD_ORDER_PENDING,
});

const preloadOrderSuccess = (payload) => ({
  type: PRELOAD_ORDER_SUCCESS,
  payload,
});

const preloadOrderError = (error) => ({
  type: PRELOAD_ORDER_FAILURE,
  error,
});

export const preloadPayPalOrder = (orderId) => {
  const payload = {
    order_id: orderId,
  };
  return (dispatch) => {
    dispatch(preloadOrderPending());
    http()
      .post("/payments/paypal_preload", payload)
      .then((response) =>
        dispatch(preloadOrderSuccess(response?.data?.message))
      )
      .catch((error) => dispatch(preloadOrderError(handleErrors(error))));
  };
};
