import React from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const handleClick = (history) => history.push("/home");

const NotFound = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => handleClick(history)}
          icon={<HomeOutlined />}
        >
          Back Home
        </Button>
      }
    />
  );
};

export default NotFound;
