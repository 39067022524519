import React from "react";
import PropTypes from "prop-types";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Input, Form, Button, Select, Alert } from "antd";

const { TextArea } = Input;

const options = [
  { value: "Account Balance Issue" },
  { value: "Net Payout Issue" },
  { value: "Total Gifted Issue" },
  { value: "Total Potential Issue" },
  { value: "Active Boards Issue" },
  { value: "Preload Gift Issue" },
  { value: "Enter Gift Issue" },
  { value: "Waiting List Issue" },
  { value: "Password Issue" },
  { value: "User Profile Issue" },
  { value: "Other" },
];

const ReportBugForm = ({ form, handleSubmit, pending, error }) => (
  <Form
    onFinish={handleSubmit}
    form={form}
    method="POST"
    initialValues={{
      category: "Account Balance Issue",
    }}
  >
    <Form.Item
      name="category"
      rules={[
        {
          required: true,
          message: "Issue category is required",
        },
      ]}
    >
      <label> Category </label>
      <Select
        name="category"
        placeholder="Select Issue Category"
        options={options}
        clearIcon
        showSearch
      />
    </Form.Item>
    <Form.Item name="issue" rules={[{ required: true }]}>
      <label>
        Issue
        <TextArea placeholder="How can we help?" rows={4} />
      </label>
    </Form.Item>
    <div className="flex-end">
      <Form.Item>
        <Button
          type="primary"
          className="float-right"
          htmlType="submit"
          icon={<CheckCircleOutlined />}
          loading={pending}
        >
          Submit Issue
        </Button>
      </Form.Item>
      <div className="bug-change-password-errors">
        {error && <Alert message={error} type="error" showIcon closable />}
      </div>
    </div>
  </Form>
);

ReportBugForm.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  pending: PropTypes.bool,
  error: PropTypes.string,
};

export default ReportBugForm;
