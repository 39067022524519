export const filterFormData = (
  paypal_url,
  fname,
  lname,
  currentUser,
  email
) => {
  var newData = {};
  const userEmail = currentUser.email === email ? undefined : email;
  newData = userEmail === undefined ? {} : { email };
  const paypalUrl =
    paypal_url === undefined
      ? currentUser.paypal_url
      : "https://www." + paypal_url;
  fname === undefined && lname === undefined
    ? paypal_url === undefined
      ? newData
      : paypalUrl === currentUser.paypal_url
      ? newData
      : (newData = { paypal_url: paypalUrl })
    : newData;
  fname === undefined && lname !== undefined
    ? paypal_url === undefined
      ? (newData = { lname })
      : paypalUrl === currentUser.paypal_url
      ? (newData = { lname })
      : (newData = { paypal_url: paypalUrl, lname })
    : newData;
  fname !== undefined && lname === undefined
    ? paypal_url === undefined
      ? (newData = { fname })
      : paypalUrl === currentUser.paypal_url
      ? (newData = { fname })
      : (newData = { paypal_url: paypalUrl, fname })
    : newData;

  fname !== undefined && lname !== undefined
    ? paypal_url === undefined
      ? (newData = { fname, lname })
      : paypalUrl === currentUser.paypal_url
      ? (newData = { fname, lname })
      : (newData = { paypal_url: paypalUrl, fname, lname })
    : newData;

  return newData;
};
