/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, Badge } from "antd";
import utils from "../../../helpers";
const ACTIVE = utils.showFoundationGift();

const GiftFoundation = ({ pending }) => {
  const history = useHistory();
  const handleFoundationGift = () => {
    history.push("/foundation-gift");
  };

  return (
    <Card style={{ padding: 0 }} className="element-box" loading={pending}>
      <h3 className="label title">Foundation Gifting</h3>
      <p className="element-description">Foundation gifting</p>

      <Button
        disabled={true}
        type="ghost"
        className={ACTIVE && `btn-outline-primary dashboard-btn`}
        disabled={!ACTIVE}
        onClick={handleFoundationGift}
      >
        Gift
      </Button>
    </Card>
  );
};
const Foundation = () =>
  ACTIVE ? (
    <GiftFoundation />
  ) : (
    <Badge.Ribbon
      text="Gift foundation is disabled temporarily"
      color="#047bf8"
    >
      <GiftFoundation />
    </Badge.Ribbon>
  );
GiftFoundation.propTypes = {
  pending: PropTypes.bool,
};

export default Foundation;
