import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import { Card } from "antd";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import ResetForm from "./ResetForm";
import { forgotPassword } from "../../actions/user/forgotPassword";

const PasswordReset = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pending, error, successMessage } = useSelector(
    (state) => state?.forgotPassword
  );

  const onSubmit = (data) => {
    dispatch(forgotPassword(data));
  };

  function changePageHandler() {
    history.push("/");
  }
  return (
    <>
      <GuestTopBar />
      <div className="login flex-jc-c flex-ai-c">
        <Card className="login-form">
          <h3 className="login-form__header title">Reset your password</h3>
          <ResetForm
            onSubmitForm={onSubmit}
            pending={pending}
            successMessage={successMessage}
            errorMessage={error}
            changePageHandler={changePageHandler}
          />
        </Card>
      </div>
    </>
  );
};

export default PasswordReset;
