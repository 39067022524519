import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card } from "antd";
import { error as errorModal } from "../../../../common/Modals";

// actions
import { foundationGiftPayPalOrder } from "../../../../actions/payment/foundationGiftPaypalOrder";

// hooks
import { useCompleteJoinBoard } from "../../../../hooks/payment";
import { useAuth } from "../../../../hooks/context";

// helpers
import storage from "../../../../helpers";
import { getFoundation } from "../../../../helpers/localStorage";

const { clearCart } = storage;

const PreloadCheckoutForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { account_balance } = user;

  const { message, error, pending } = useSelector(
    (state) => state?.giftFoundationOrder
  );

  useCompleteJoinBoard(message);

  const handleCancel = () => {
    clearCart();
    history.push("/home");
  };

  const amount = parseFloat(getFoundation(), 10);

  const handleSubmit = () => {
    const payload = {
      amount,
      source: "USER_ACCOUNT",
    };
    dispatch(foundationGiftPayPalOrder(payload));
  };

  useEffect(() => {
    if (amount > parseFloat(account_balance, 10)) {
      clearCart();
      errorModal("You do not have enough money in your account", history);
      history.push("/home");
    }
  }, [amount]);
  return (
    <div className="preload-payment">
      <h3 className="element-header">Billing Details</h3>
      <Card className="gift-payment-container">
        <p>Transfer from Preloaded Account to Foundation Gift</p>
        <h3>Total = ${amount}</h3>
      </Card>
      <div className="flex flex-jc-sb gift-payment-checkout-btn">
        <Button
          onClick={handleCancel}
          type="ghost"
          className="btn-outline-primary"
          icon={<CloseCircleOutlined />}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="ghost"
          className="btn-outline-primary"
          icon={<CheckCircleOutlined />}
          loading={!error && pending}
        >
          Checkout
        </Button>
      </div>
      <div className="gift-payment-errors">
        {error && <Alert message={error} type="error" closable showIcon />}
      </div>
    </div>
  );
};

export default PreloadCheckoutForm;
