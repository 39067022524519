import React from "react";

// components
import Dashboard from "..";
import Faq from "../../../common/Faq";

const Index = () => (
  <Dashboard>
    <Faq />
  </Dashboard>
);

export default Index;
