import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// components
import { Card } from "antd";
import UserForm from "./Form";

// actions
import { updateUser } from "../../../../actions/user/updateUser";

//hooks
import { useCompleteJoinBoard } from "../../../../hooks/payment";

// helpers
import helpers from "../../../../helpers";

const { filterFormData } = helpers;

const ProfileForm = ({
  updateUserInfo,
  fhidden,
  lhidden,
  user: currentUser,
}) => {
  const dispatch = useDispatch();

  const { pending, error, user } = useSelector((state) => state?.updateUser);

  useCompleteJoinBoard(user);

  const handleSubmit = (data) => {
    const { fname, lname, paypal_url, email } = data;

    const newFilteredData = filterFormData(
      paypal_url,
      fname,
      lname,
      currentUser,
      email
    );
    updateUserInfo(true);
    dispatch(updateUser(newFilteredData));
  };

  return (
    <Card>
      <UserForm
        handleSubmit={handleSubmit}
        error={error}
        pending={pending}
        user={currentUser}
        fhidden={fhidden}
        lhidden={lhidden}
      />
    </Card>
  );
};
ProfileForm.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  fhidden: PropTypes.bool,
  lhidden: PropTypes.bool,
  user: PropTypes.object,
};
export default ProfileForm;
