export const saveItem = (name, value) => {
  const { sessionStorage } = window;
  sessionStorage.setItem(name, value);
};

// Payment details on local storage
export const getIntent = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("intent");
};

export const getPaymentId = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("paymentId");
};

export const addCart = (value) => {
  const { sessionStorage } = window;
  const data = sessionStorage.getItem("cart") || "{}";

  const updatedCart = {
    ...JSON.parse(data),
    ...value,
  };
  sessionStorage.setItem("cart", JSON.stringify(updatedCart));
};

export const getCart = () => JSON.parse(sessionStorage.getItem("cart") || "{}");

export const getPreload = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("preload");
};
export const getFoundation = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("foundation_gift");
};

export const getFoundationSourceType = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("foundation_gift_source");
};
export const getType = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("type");
};
export const clearCart = () => {
  const { sessionStorage } = window;
  sessionStorage.removeItem("intent");
  sessionStorage.removeItem("paymentId");
  sessionStorage.removeItem("cart");
  sessionStorage.removeItem("preload");
  sessionStorage.removeItem("foundation");
  sessionStorage.removeItem("foundation_gift");
  sessionStorage.removeItem("type");
};

// User details on sessionStorage
export const clearLocalStorage = () => {
  const { sessionStorage } = window;
  sessionStorage.clear();
};

export const getToken = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("token");
};

export const getUid = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("uid");
};

export const getExpiry = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("expiry");
};

export const getClient = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("client");
};

export const getTokenType = () => {
  const { sessionStorage } = window;
  return sessionStorage.getItem("token_type");
};
