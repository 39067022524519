import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { Button, Table, Card } from "antd";
import Dashboard from "../index";
import columns from "../../../common/Boards/columns";

// hooks
import { useAuth } from "../../../hooks/context";

// actions
import { getBoards } from "../../../actions/board/get";

// helpers
import storage from "../../../helpers";
import utils from "../../../helpers";

const ACTIVE = utils.showEnterGift();

const { getCart, calculateTotal, saveItems } = storage;

const GiftOrder = () => {
  const stored = getCart();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();

  // local state
  const [total, setTotal] = useState(0);
  const [boardInfo, setBoardInfo] = useState([]);
  const [count, setCount] = useState(0);
  const [inputs, setInputs] = useState(stored?.inputs);

  // redux state
  const { boards, pending: boardsPending } = useSelector(
    (state) => state?.boards
  );

  useEffect(() => {
    user?.community_id && dispatch(getBoards(user?.community_id));
  }, [user]);

  useEffect(() => {
    if (inputs && Object.values(inputs).length) {
      const [total, data, quantity] = calculateTotal(inputs, boards);
      setTotal(total);
      setCount(quantity);
      setBoardInfo(data);
    }
  }, [inputs, boards]);

  const handleSubmit = () => {
    if (total > 0) {
      // saves to session storage
      saveItems("normal", null, null, inputs, total, boardInfo);
      history.push("/gift-checkout");
    }
  };

  const handleInputChange = (value, board) => {
    setInputs({ ...inputs, [board]: value });
  };

  return (
    <Dashboard>
      <Card className="gift" size="small">
        <h2 className="title element-header">Select Board To Join</h2>
        <Table
          dataSource={boards}
          columns={columns(handleInputChange, total, inputs, count)}
          pagination={{ pageSize: 5 }}
          loading={boardsPending || user?.pending}
          footer={() => (
            <h3 className="title gift-total align-right">Total = ${total}</h3>
          )}
          size="small"
        />
        <div className="gift-btn">
          <Button
            type="primary"
            disabled={total <= 0 || !boards || count > 5 || !ACTIVE}
            onClick={handleSubmit}
            loading={boardsPending}
          >
            Proceed To Checkout
          </Button>
        </div>
      </Card>
    </Dashboard>
  );
};

export default GiftOrder;
