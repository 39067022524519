import React, { useEffect } from "react";
import { Card } from "antd";
import PropTypes from "prop-types";
import { reportIssue } from "../../../../actions/reportIssues/report";
import { useDispatch, useSelector } from "react-redux";

import { success } from "../../../../common/Modals";
import { useHistory } from "react-router-dom";

import ReportBugForm from "./reportBugForm";
import { Form } from "antd";

const ReportBug = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const { reported, pending, error } = useSelector(
    (state) => state?.reportIssue
  );

  useEffect(() => {
    if (reported === true) {
      success("You issue have been reported", history);
      history.push("/home");
    }
  }, [reported]);

  const handleSubmit = ({ category, issue }) => {
    let newData = {
      email: user?.email,
      category,
      issue,
    };
    dispatch(reportIssue(newData));
  };

  return (
    <Card
      className="dashboard-payment"
      bordered
      title="Give details about your issue"
    >
      <ReportBugForm
        pending={pending}
        user={user}
        error={error}
        handleSubmit={handleSubmit}
        form={form}
      />
    </Card>
  );
};

ReportBug.propTypes = {
  user: PropTypes.object,
};
export default ReportBug;
