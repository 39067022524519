import utils from "../../helpers";
import {
  BEGIN_PAYMENT_PENDING,
  BEGIN_PAYMENT_SUCCESS,
  BEGIN_PAYMENT_FAILURE,
} from "./types";

const { http, handleErrors, showPreload } = utils;

const preloadPaymentPending = () => ({
  type: BEGIN_PAYMENT_PENDING,
});

const preloadPaymentSuccess = (payload) => ({
  type: BEGIN_PAYMENT_SUCCESS,
  payload,
});

const preloadPaymentError = (error) => ({
  type: BEGIN_PAYMENT_FAILURE,
  error,
});

export const preloadPayment = (amount) => {
  const payload = {
    preload_params: { amount },
  };
  return (dispatch) => {
    dispatch(preloadPaymentPending());
    showPreload() &&
      http()
        .post("/payments/preload", payload)
        .then((response) => dispatch(preloadPaymentSuccess(response?.data)))
        .catch((error) => dispatch(preloadPaymentError(handleErrors(error))));
  };
};
