import utils from "../../helpers";
import {
  UPDATE_USER_PENDING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./types";
const { http, handleErrors } = utils;

export const updateUserPending = () => ({
  type: UPDATE_USER_PENDING,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

export const updateUser = (user) => {
  return (dispatch) => {
    if (Object.keys(user).length === 0) {
      dispatch(
        updateUserSuccess(
          "Nothing to update.Information given is the same as the one we have."
        )
      );
    }
    dispatch(updateUserPending());
    http()
      .put("/user", user)
      .then((response) => dispatch(updateUserSuccess(response?.data)))
      .catch((error) => dispatch(updateUserFailure(handleErrors(error))));
  };
};
