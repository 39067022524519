import utils from "../../helpers";
import {
  GET_INVITE_BOARD_PENDING,
  GET_INVITE_BOARD_SUCCESS,
  GET_INVITE_BOARD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getInviteBoardPending = () => ({
  type: GET_INVITE_BOARD_PENDING,
});

export const getInviteBoardSuccess = (payload) => ({
  type: GET_INVITE_BOARD_SUCCESS,
  payload,
});

export const getInviteBoardFailure = (error) => ({
  type: GET_INVITE_BOARD_FAILURE,
  error,
});

export const getBoardInvites = () => {
  return (dispatch) => {
    dispatch(getInviteBoardPending());
    http()
      .get("/admin_invites")
      .then((response) =>
        dispatch(getInviteBoardSuccess(response.data?.pending_invites))
      )
      .catch((error) => dispatch(getInviteBoardFailure(handleErrors(error))));
  };
};
