import React from "react";

// components
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import Faq from "../../common/Faq";

const Index = () => (
  <>
    <GuestTopBar />
    <div className="faq-guest">
      <Faq />
    </div>
  </>
);

export default Index;
