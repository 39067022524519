import {
  INVITE_BOARD_PENDING,
  INVITE_BOARD_SUCCESS,
  INVITE_BOARD_FAILURE,
} from "../../actions/board/types";

const initialState = {
  invite: null,
  pending: false,
  error: null,
};

const inviteBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_BOARD_PENDING:
      return { pending: true };
    case INVITE_BOARD_SUCCESS:
      return {
        invite: action.payload,
        pending: false,
      };
    case INVITE_BOARD_FAILURE:
      return {
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};

export default inviteBoardReducer;
