import utils from "../../helpers";
import {
  COMMUNITY_STATS_PENDING,
  COMMUNITY_STATS_SUCCESS,
  COMMUNITY_STATS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const communityStatsPending = () => ({
  type: COMMUNITY_STATS_PENDING,
});

export const communityStatsSuccess = (payload) => ({
  type: COMMUNITY_STATS_SUCCESS,
  payload,
});

export const communityStatsFailure = (error) => ({
  type: COMMUNITY_STATS_FAILURE,
  error,
});

export const communityStats = () => {
  return (dispatch) => {
    dispatch(communityStatsPending());
    http()
      .get(`/communities/status`)
      .then((response) =>
        dispatch(communityStatsSuccess(response?.data.community_stats))
      )
      .catch((error) =>
        dispatch(communityStatsFailure(handleErrors(error, "fundboards")))
      );
  };
};
