import { saveItem, addCart } from "./localStorage";

export const displayErrors = (error) => {
  if (
    error &&
    error[0] ===
      "Could not determine which URL to request: Stripe::Customer instance has invalid ID: nil"
  ) {
    return "Go to the settings page to set up your payment before proceeding!";
  }
  return error;
};

const getFundBoardId = (boards, value) =>
  boards?.find((board) => board.gift_in == value)?.id;

export const calculateTotal = (inputs, boards) => {
  let total = 0;
  let data = [];
  let quantity = 0;
  total =
    inputs &&
    Object.keys(inputs).reduce((acc, board) => {
      const fundboard_id = getFundBoardId(boards, board);
      data.push({ fundboard_id, quantity: Number(inputs[board]) });
      quantity += Number(inputs[board]);
      return acc + (Number(inputs[board]) || 0) * Number(board);
    }, 0);
  data = data.filter((b) => b.quantity > 0);
  return [total, data, quantity];
};

export const saveItems = (type, amount, begin, inputs, total, boardInfo) => {
  if (type === "preload") {
    saveItem("preload", amount);
  } else if (type === "foundation_gift") {
    saveItem("foundation_gift", amount);
  } else {
    addCart({ inputs, total, boardInfo });
  }
  saveItem("paymentId", begin?.payment_method_id);
  saveItem("intent", begin?.payment_intent_client_secret);
};
