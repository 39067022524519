import React from "react";
import PropTypes from "prop-types";

// components
import { Timeline, Button } from "antd";
import { LoadingOutlined, ArrowDownOutlined } from "@ant-design/icons";

// helpers
import helpers from "../../../helpers";
const { formatDate,calcPositions,getStatus ,getColor } = helpers;

export const ClickableBodyRow = ({ style, ...restProps }) => {
  const ref = React.useRef();
  return (
    <tr ref={ref} style={{ cursor: "pointer", ...style }} {...restProps} />
  );
};

export const components = {
  body: {
    row: ClickableBodyRow,
  },
};

export const expandedRowRender = (data, pending, lenHandler, len) => {
  return pending ? (
    <div className="flex flex-jc-c">
      <LoadingOutlined />
    </div>
  ) : data?.length ? (
    <>
      <Timeline mode="left">
        {data?.slice(0, len).map((x) => {
          return (
            <Timeline.Item
              label={formatDate(x.joined_on)}
              key={`${x.position}-${x.gift_in}`}
              color={getColor(calcPositions(x))}
            >
              <p>
                <strong>FundBoard:</strong> ${x.gift_in}
              </p>
              <p>
                <strong>Potential:</strong> ${x.full_potential}
              </p>
              <p>
                <strong>Position:</strong>
                {calcPositions(x)}
              </p>
              <p>
                {x.fully_gifted === true ? null : (
                  <>
                    <strong>Status:&nbsp;</strong>
                    {getStatus(x.status)}
                  </>
                )}
              </p>
            </Timeline.Item>
          );
        })}
      </Timeline>
      {data.length > len && (
        <Button
          type="primary"
          style={{ margin: "auto", display: "block" }}
          icon={<ArrowDownOutlined />}
          onClick={() => lenHandler(len + 4)}
        >
          More
        </Button>
      )}
    </>
  ) : (
    <p className="align-center">There are no promotions on this fund board.</p>
  );
};

ClickableBodyRow.propTypes = {
  style: PropTypes.object,
};
