import utils from "../../helpers";
import {
  GET_ACTIVELEVEL_PENDING,
  GET_ACTIVELEVEL_SUCCESS,
  GET_ACTIVELEVEL_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getActiveLevelPending = () => ({
  type: GET_ACTIVELEVEL_PENDING,
});

export const getActiveLevelSuccess = (payload) => ({
  type: GET_ACTIVELEVEL_SUCCESS,
  payload,
});

export const getActiveLevelFailure = (error) => ({
  type: GET_ACTIVELEVEL_FAILURE,
  error,
});

export const getActiveLevel = () => {
  return (dispatch) => {
    dispatch(getActiveLevelPending());
    http()
      .get("/communities/1/fundboards")
      .then((response) => dispatch(getActiveLevelSuccess(response.data)))
      .catch((error) => dispatch(getActiveLevelFailure(handleErrors(error))));
  };
};
