import {
  GET_STRIPE_CONNECT_SUCCESS,
  GET_STRIPE_CONNECT_FAILURE,
  GET_STRIPE_CONNECT_PENDING,
} from "../../actions/stripeConnect/types";

const initialState = {
  stripeConnect: null,
  pending: false,
  error: null,
  has: false,
};

const getStripeConnectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STRIPE_CONNECT_PENDING:
      return { pending: true };
    case GET_STRIPE_CONNECT_SUCCESS:
      return {
        stripeConnect: action.payload,
        pending: false,
        error: null,
        has: true,
      };
    case GET_STRIPE_CONNECT_FAILURE:
      return {
        error: action.error,
        pending: false,
        stripeConnect: null,
        has: false,
      };
    default:
      return state;
  }
};

export default getStripeConnectReducer;
