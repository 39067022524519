import utils from "../../helpers";
import { SIGNUP_PENDING, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "./types";

const { http, handleErrors } = utils;

export const signUpPending = () => ({
  type: SIGNUP_PENDING,
});

export const signUpSuccess = (payload) => ({
  type: SIGNUP_SUCCESS,
  payload,
});

export const signUpFailure = (error) => ({
  type: SIGNUP_FAILURE,
  error,
});

export const signup = (user) => {
  return (dispatch) => {
    dispatch(signUpPending());
    http()
      .post("/auth", { ...user })
      .then((response) =>
        dispatch(
          signUpSuccess({
            data: response?.data,
            token: response?.headers["access-token"],
            token_type: response?.headers["token-type"],
            uid: response?.headers["uid"],
            expiry: response?.headers["expiry"],
            client: response?.headers["client"],
          })
        )
      )
      .catch((error) => dispatch(signUpFailure(handleErrors(error))));
  };
};
