import React from "react";
import PropTypes from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

const Summary = ({ foundation, handleChangeCart }) => {
  return (
    <div className="preload-summary">
      <h3 className="element-header">Foundation Gift Summary</h3>
      <p>The amount below will be send to the Foundation</p>
      <h3>Total = ${foundation}</h3>
      <div className="preload-btn">
        <Button
          icon={<EditOutlined />}
          onClick={handleChangeCart}
          type="primary"
        >
          Change
        </Button>
      </div>
    </div>
  );
};

Summary.propTypes = {
  handleChangeCart: PropTypes.func,
  foundation: PropTypes.string,
};

export default Summary;
