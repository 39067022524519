import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { Loading3QuartersOutlined } from "@ant-design/icons";

import Home from "./Dashboard/Home";

import GiftOrder from "./Dashboard/GiftPayment";
import GiftCheckout from "./Dashboard/GiftPayment/Checkout";

import PayPalPreloadGift from "./Dashboard/Preload";
import PayPalPreloadCheckout from "./Dashboard/Preload/Checkout";

import FoundationGifting from "./Dashboard/GiftingFoundation";
import FoundationCheckout from "./Dashboard/GiftingFoundation/Checkout";

import Payment from "./Payment";
import Settings from "./Dashboard/Settings";
import ReportBug from "./Dashboard/ReportBug";
import CommunityStats from "./Dashboard/CommunityStats";
import WaitingList from "./Dashboard/WaitingList";
import ActiveBoards from "./Dashboard/ActiveBoards";
import FundBoardsInvites from "./Dashboard/FundBoardsInvites";

import AdminBoard from "./Dashboard/Admin/FundBoard";
import UserAccounts from "./Dashboard/Admin/accounts";
import Payouts from "./Dashboard/Admin/Payouts";
import Faq from "./Dashboard/Faq";

import Login from "./Login";
import Signup from "./Signup";

import RecoverPassword from "./PasswordRecovery";
import PasswordReset from "./ResetPassword";
import Faq_guest from "./Faq";

import NotFound from "./NotFound";
import Invite from "./Invitation";
import "../styles/index.scss";

import ProtectedRoute from "./Routes/Protected";
import AdminRoute from "./Routes/Admin";
import PublicRoute from "./Routes/Public";
import { AuthContext } from "../hooks/context";

import { error as errorModal } from "../common/Modals";

// actions
import { getCurrentUser } from "../actions/user/currentUser";

// helpers
import helpers from "../helpers";

const { getToken, getUid, clearLocalStorage } = helpers;

const App = () => {
  const dispatch = useDispatch();
  const {
    user: currentUser,
    error,
    pending,
  } = useSelector((state) => state?.currentUser);

  const user = { ...currentUser, error, pending };

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    error &&
      getUid() &&
      errorModal(
        "Your session has expired, click the button to logout",
        null,
        clearLocalStorage
      );
  }, [error]);

  return (
    <AuthContext.Provider value={{ getToken, getUid, user }}>
      {pending ? (
        <div className="dashboard-loader">
          <Loading3QuartersOutlined spin />
        </div>
      ) : (
        <Switch>
          <ProtectedRoute path="/home" component={Home} />
          <ProtectedRoute path="/payment" component={Payment} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/report-bug" component={ReportBug} />
          <ProtectedRoute path="/community-stats" component={CommunityStats} />
          <ProtectedRoute path="/active-boards" component={ActiveBoards} />
          <ProtectedRoute path="/gift-order" component={GiftOrder} />
          <ProtectedRoute path="/gift-checkout" component={GiftCheckout} />
          <ProtectedRoute path="/preload-gift" component={PayPalPreloadGift} />
          <ProtectedRoute
            path="/preload-checkout"
            component={PayPalPreloadCheckout}
          />
          <ProtectedRoute
            path="/foundation-checkout"
            component={FoundationCheckout}
          />

          <ProtectedRoute
            path="/foundation-gift"
            component={FoundationGifting}
          />
          <ProtectedRoute path="/waiting-list" component={WaitingList} />
          <ProtectedRoute path="/invites" component={FundBoardsInvites} />
          <ProtectedRoute path="/payouts" component={Payouts} />
          <ProtectedRoute path="/faq" component={Faq} />

          <AdminRoute path="/admin/board" component={AdminBoard} />
          <AdminRoute path="/admin/user-account" component={UserAccounts} />
          <PublicRoute path="/invite/:invitation_token" component={Invite} />
          <PublicRoute path="/reset-password" component={PasswordReset} />
          <PublicRoute path="/recover" component={RecoverPassword} />
          <PublicRoute path="/faq-guest" component={Faq_guest} />
          <PublicRoute
            exact
            path="/9f0045e5998a131fd0f2aaef8b960f6d"
            component={Signup}
          />
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute component={NotFound} />
        </Switch>
      )}
    </AuthContext.Provider>
  );
};

export default App;
