import { http } from "./axios";
import { countries } from "./countries";
import * as storage from "./localStorage";
import * as payment from "./payment";

import { filterFormData } from "./userProfileFilter";
import { reload } from "./reload";
import { formatDate } from "./formatDate";
import { capitalize } from "./capitalize";
import { getStatus } from "./getStatus";

import { isAdmin } from "./roles";
import {
  showPreload,
  showEnterGift,
  showFoundationGift,
  showCommunityStatus,
} from "./betaFeatures";
import { handleErrors } from "./handleErrors";
import { range, formatter, currencyToNumber, getUrlParams } from "./utils";
import {calcPositions} from "./calcPositions";
import {getColor} from './getColor'
export default {
  range,
  formatter,
  currencyToNumber,
  getUrlParams,
  handleErrors,
  http,
  countries,
  ...storage,
  ...payment,
  filterFormData,
  showPreload,
  showFoundationGift,
  showCommunityStatus,
  showEnterGift,
  reload,
  formatDate,
  capitalize,
  isAdmin,
  getStatus,
  calcPositions,
  getColor
};
