export const formatDate = (value) => {
  const dtFormat = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    minute: "numeric",
    hour: "numeric",
  });

  return value ? dtFormat.format(new Date(value)) : "";
};
