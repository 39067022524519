import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";

const Navbar = ({ Links, Logo, onClose, visible }) => {
  return (
    <Drawer
      title={<Logo />}
      placement="left"
      onClose={onClose}
      visible={visible}
      closable
    >
      <Links />
    </Drawer>
  );
};

Navbar.propTypes = {
  Links: PropTypes.any,
  Logo: PropTypes.any,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default Navbar;
