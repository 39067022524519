import {
  CREATE_PAYMENT_PENDING,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
} from "./types";

const createPaymentPending = () => ({
  type: CREATE_PAYMENT_PENDING,
});

const createPaymentSuccess = (payload) => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload,
});

const createPaymentError = (error) => ({
  type: CREATE_PAYMENT_FAILURE,
  error,
});

const handleStripeResponse = (response, dispatch) => {
  response?.error
    ? dispatch(createPaymentError(response))
    : dispatch(createPaymentSuccess(response));
};

export const createPayment = (stripe, CardNumberElement, billing_details) => {
  const payload = {
    type: "card",
    card: CardNumberElement,
    billing_details,
  };
  return (dispatch) => {
    dispatch(createPaymentPending());
    stripe
      .createPaymentMethod(payload)
      .then((response) => handleStripeResponse(response, dispatch))
      .catch((error) => dispatch(createPaymentError(error)));
  };
};
