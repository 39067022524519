import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, Badge } from "antd";
import utils from "../../../helpers";

const ACTIVE = utils.showCommunityStatus();
const Status = ({ pending }) => {
  const history = useHistory();
  const handleCommunityStats = () => {
    history.push("/community-stats");
  };

  return (
    <Card style={{ padding: 0 }} className="element-box" loading={pending}>
      <h3 className="label title">Community Status</h3>
      <p className="element-description">View Status</p>
      <Button
        type="ghost"
        onClick={handleCommunityStats}
        className={ACTIVE && `btn-outline-primary dashboard-btn`}
        disabled={!ACTIVE}
      >
        View
      </Button>
    </Card>
  );
};

const Stat = () =>
  ACTIVE ? (
    <Status />
  ) : (
    <Badge.Ribbon text="Community status disabled temporarily" color="#047bf8">
      <Status />
    </Badge.Ribbon>
  );

Status.propTypes = {
  pending: PropTypes.bool,
};

export default Stat;
