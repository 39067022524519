import utils from "../../helpers";
import { STAT_PENDING, STAT_SUCCESS, STAT_FAILURE } from "./types";

const { http, handleErrors } = utils;

export const statPending = () => ({
  type: STAT_PENDING,
});

export const statSuccess = (payload) => ({
  type: STAT_SUCCESS,
  payload,
});

export const statFailure = (error) => ({
  type: STAT_FAILURE,
  error,
});

export const getStat = () => {
  return (dispatch) => {
    dispatch(statPending());
    http()
      .get("/user-fundboards-stats")
      .then((response) => dispatch(statSuccess(response.data.stats)))
      .catch((error) => dispatch(statFailure(handleErrors(error))));
  };
};
