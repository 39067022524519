import utils from "../../helpers";
import {
  TRANSFER_TO_ACCOUNT_PENDING,
  TRANSFER_TO_ACCOUNT_SUCCESS,
  TRANSFER_TO_ACCOUNT_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const transferToAccountPending = () => ({
  type: TRANSFER_TO_ACCOUNT_PENDING,
});

export const transferToAccountSuccess = (payload) => ({
  type: TRANSFER_TO_ACCOUNT_SUCCESS,
  payload,
});

export const transferToAccountFailure = (error) => ({
  type: TRANSFER_TO_ACCOUNT_FAILURE,
  error,
});

export const transferToAccount = (id) => {
  return (dispatch) => {
    dispatch(transferToAccountPending());
    http()
      .put(`/fundboard_memberships/${id}/send_to_account`)
      .then((response) =>
        dispatch(transferToAccountSuccess(response.data))
      )
      .catch((error) =>
        dispatch(transferToAccountFailure(handleErrors(error)))
      );
  };
};
