import React from "react";
import { Link } from "react-router-dom";

const Logo = () => (
  <div className="logo-text">
    <Link to={"/home"}>Our giving Board</Link>
  </div>
);

export default Logo;
