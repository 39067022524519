import utils from "../../helpers";
import {
  PROMOTIONS_PENDING,
  PROMOTIONS_SUCCESS,
  PROMOTIONS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getPromotionsPending = () => ({
  type: PROMOTIONS_PENDING,
});

export const getPromotionsSuccess = (payload) => ({
  type: PROMOTIONS_SUCCESS,
  payload,
});

export const getPromotionsFailure = (error) => ({
  type: PROMOTIONS_FAILURE,
  error,
});

export const getPromotions = (id) => {
  return (dispatch) => {
    dispatch(getPromotionsPending());
    http()
      .get(`fundboard_memberships/${id}/promotions`)
      .then((response) =>
        dispatch(getPromotionsSuccess(response?.data.promotions))
      )
      .catch((error) => dispatch(getPromotionsFailure(handleErrors(error))));
  };
};
