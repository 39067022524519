import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import UserPayouts from "./user";
import AdminPayouts from "./admin";

// actions
import { getMemberships } from "../../../../actions/board/membership";
import { payMemberships } from "../../../../actions/board/pay";

// helpers
import utils from "../../../../helpers";

// hooks
import { useAuth } from "../../../../hooks/context";

const getMembershipsIds = (m) => {
  let ids = [];
  m.map((d) => (ids = [...ids, ...d.membership_ids]));
  return ids;
};

const Payouts = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [membershipIds, setMembershipIds] = useState([]);

  const { pending, data } = useSelector((state) => state?.membership);

  const { pending: pPending, data: pData } = useSelector(
    (state) => state?.payMembership
  );
  const isAdmin = utils.isAdmin(user);

  const rowSelection = {
    onChange: (_, data) => {
      setMembershipIds(data);
    },
  };

  useEffect(() => pData && dispatch(getMemberships()), [pData]);
  useEffect(() => dispatch(getMemberships()), []);
  const refresh = () => dispatch(getMemberships());
  const onPay = () => {
    dispatch(payMemberships(getMembershipsIds(membershipIds)));
    setMembershipIds([]);
  };

  return isAdmin ? (
    <AdminPayouts
      membershipIds={membershipIds}
      rowSelection={rowSelection}
      pPending={pPending}
      refresh={refresh}
      pending={pending}
      isAdmin={isAdmin}
      onPay={onPay}
      user={user}
      data={data}
    />
  ) : (
    <UserPayouts
      rowSelection={rowSelection}
      pPending={pPending}
      refresh={refresh}
      pending={pending}
      data={data}
      user={user}
    />
  );
};
export default Payouts;
