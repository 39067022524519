import React from "react";

// components
import { SelectOutlined } from "@ant-design/icons";
import { Button } from "antd";

const columns = (isAdmin, user) => [
  {
    title: "Name",
    render: (data) => `${data?.fname} ${data?.lname}`,
    responsive: ["lg"],
  },
  {
    title: "Email",
    render: (data) => (isAdmin ? `${data?.email}` : `${user?.email}`),
    filtered: true,
  },
  {
    title: "Total Amount",
    dataIndex: "total_net_payout",
  },
  {
    title: "PayPal Link",
    // eslint-disable-next-line
    render: (data) => (
      <Button
        type="link"
        disabled={isAdmin ? !data?.paypal_url : !user?.paypal_url}
        href={isAdmin ? data?.paypal_url : user?.paypal_url}
        icon={<SelectOutlined rotate={90} />}
        size="small"
        target="_blank"
      >
        {data?.paypal_url}
      </Button>
    ),
  },
];

export default columns
