import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Table, Card, Button } from "antd";
import Dashboard from "../index";
import { SyncOutlined } from "@ant-design/icons";

// actions
import { communityStats as communityStatsAction } from "../../../actions/communityStats/getCommunityStats";

const columns = [
  {
    title: "Level",
    dataIndex: "level",
    key: "level",
    render: (level) => `${level - 2}`,
  },
  {
    title: "Gift In",
    dataIndex: "gift_in",
    key: "gift_in",
    render: (item) => `$${item}`,
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (item) => (item === true ? "Live" : "Not Live"),
  },
  {
    title: "Gifts Needed",
    dataIndex: "gifts_remaining_to_promotion",
    key: "gifts_remaining_to_promotion",
  },
  {
    title: "Memberships",
    dataIndex: "memberships",
    key: "memberships",
  },
  {
    title: "Not Promoted",
    dataIndex: "not_promoted",
    key: "not_promoted",
  },
];

const CommunityStats = () => {
  const { communityStats, pending } = useSelector(
    (state) => state?.communityStats
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(communityStatsAction());
  }, []);
  const refresh = () => dispatch(communityStatsAction());

  console.log(communityStats?.fundboards);
  return (
    <Dashboard>
      <Card className="community-stats" size="small">
        <h3 className="element-header gift-title">Community Status</h3>
        <div className="flex flex-jc-fe flex-row-gap-2">
          <Button
            type="dashed"
            icon={<SyncOutlined />}
            onClick={refresh}
            loading={pending}
          >
            Refresh
          </Button>
        </div>
        <br />
        <Table
          dataSource={communityStats?.fundboards?.filter(
            (m) => m.level !== 6 && m.level !== 1 && m.level !== 2
          )}
          columns={columns}
          pagination={{ pageSize: 5 }}
          loading={pending}
        />

        <br />
        <div>
          <h3 className="element-header  gift-title">Foundation Gift</h3>
          <h3>Available Balance : {communityStats?.foundation_gifts}</h3>
          <h3>
            Currently gifting level :{" "}
            {communityStats?.active_foundation_level - 2}
          </h3>
        </div>
      </Card>
    </Dashboard>
  );
};

export default CommunityStats;
