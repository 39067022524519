import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Popover, Avatar, Card, Menu, List, Badge } from "antd";
import {
  UserOutlined,
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import Banner from "../../../../common/Banner/Alert";
import { useAuth } from "../../../../hooks/context";

// actions
import { logout } from "../../../../actions/user/logout";

const NotificationsMenu = () => (
  <Card bordered={false} size="small">
    <p>You have {0} notifications</p>
  </Card>
);

const AvatorMenu = ({ history, handleLogout }) => {
  return (
    <List>
      <Menu style={{ border: "none" }}>
        <Menu.Item
          key="1"
          icon={<SettingOutlined />}
          onClick={() => history.push("/settings")}
        >
          Settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    </List>
  );
};

AvatorMenu.propTypes = { history: PropTypes.any, handleLogout: PropTypes.func };

const DesktopBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const message = `An email with instructions on how to confirm your new email address has been sent to ${user?.unconfirmed_email}. Please confirm the email address to use it.`;

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="desktop-bar hide-for-mobile">
        <Popover
          className="dashboard-desktop__bar-bell"
          content={<NotificationsMenu />}
          trigger="click"
          title="Notifications"
          arrow
        >
          <Badge count={0}>
            <Avatar shape="circle" icon={<BellOutlined />} />
          </Badge>
        </Popover>
        <Popover
          content={<AvatorMenu history={history} handleLogout={handleLogout} />}
          trigger="click"
          className="dashboard-desktop__bar-bell"
          arrow
        >
          <Avatar shape="circle" size="medium" icon={<UserOutlined />} />
        </Popover>
      </div>
      <div className="banner hide-for-mobile">
        {user.unconfirmed_email && <Banner type="info" message={message} />}
      </div>
    </>
  );
};

export default DesktopBar;
