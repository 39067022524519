import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/context";
import utils from "../../helpers";

const Admin = ({ component: Component, ...rest }) => {
  const { getToken, user } = useAuth();

  const isAdmin = utils.isAdmin(user);

  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() && isAdmin ? <Component {...props} /> : <Redirect to="//" />
      }
    />
  );
};

Admin.propTypes = {
  component: PropTypes.any,
};

export default Admin;
