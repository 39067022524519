// create
export const CREATE_BOARD_PENDING = "CREATE_BOARD_PENDING";
export const CREATE_BOARD_SUCCESS = "CREATE_BOARD_SUCCESS";
export const CREATE_BOARD_FAILURE = "CREATE_BOARD_FAILURE";

// delete
export const DELETE_BOARD_PENDING = "DELETE_BOARD_PENDING";
export const DELETE_BOARD_SUCCESS = "DELETE_BOARD_SUCCESS";
export const DELETE_BOARD_FAILURE = "DELETE_BOARD_FAILURE";

// update
export const UPDATE_BOARD_PENDING = "UPDATE_BOARD_PENDING";
export const UPDATE_BOARD_SUCCESS = "UPDATE_BOARD_SUCCESS";
export const UPDATE_BOARD_FAILURE = "UPDATE_BOARD_FAILURE";

// get
export const GET_BOARD_PENDING = "GET_BOARD_PENDING";
export const GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS";
export const GET_BOARD_FAILURE = "GET_BOARD_FAILURE";

// invite
export const INVITE_BOARD_PENDING = "INVITE_BOARD_PENDING";
export const INVITE_BOARD_SUCCESS = "INVITE_BOARD_SUCCESS";
export const INVITE_BOARD_FAILURE = "INVITE_BOARD_FAILURE";

// board invites
export const GET_INVITE_BOARD_PENDING = "GET_INVITE_BOARD_PENDING";
export const GET_INVITE_BOARD_SUCCESS = "GET_INVITE_BOARD_SUCCESS";
export const GET_INVITE_BOARD_FAILURE = "GET_INVITE_BOARD_FAILURE";

// board membership
export const GET_MEMBERSHIPS_PENDING = "GET_MEMBERSHIPS_PENDING";
export const GET_MEMBERSHIPS_SUCCESS = "GET_MEMBERSHIPS_SUCCESS";
export const GET_MEMBERSHIPS_FAILURE = "GET_MEMBERSHIPS_FAILURE";

// board membership
export const PAY_MEMBERSHIPS_PENDING = "PAY_MEMBERSHIPS_PENDING";
export const PAY_MEMBERSHIPS_SUCCESS = "PAY_MEMBERSHIPS_SUCCESS";
export const PAY_MEMBERSHIPS_FAILURE = "PAY_INVITE_BOARD_FAILURE";

// transfer to account
export const TRANSFER_TO_ACCOUNT_PENDING = "TRANSFER_TO_ACCOUNT_PENDING";
export const TRANSFER_TO_ACCOUNT_SUCCESS = "TRANSFER_TO_ACCOUNT_SUCCESS";
export const TRANSFER_TO_ACCOUNT_FAILURE = "TRANSFER_TO_ACCOUNT_FAILURE";

// reverse 
export const REVERSE_TRANSFER_PENDING = "REVERSE_TRANSFER_PENDING";
export const REVERSE_TRANSFER_SUCCESS = "REVERSE_TRANSFER_SUCCESS";
export const REVERSE_TRANSFER_FAILURE = "REVERSE_TRANSFER_FAILURE";
