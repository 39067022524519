import React from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import { Form, Card } from "antd";
import ChangePasswordForm from "./ChangePasswordForm";

// actions
import { newPassword } from "../../../../actions/user/newPassword";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // redux state
  const { success, pending, error } = useSelector(
    (state) => state?.newPassword
  );

  // redux state
  const handleSubmit = (data) => {
    dispatch(newPassword(data));
  };

  return (
    <Card className="dashboard-payment" bordered title="Change Password">
      <ChangePasswordForm
        form={form}
        handleSubmit={handleSubmit}
        errors={error}
        pending={pending}
        success={success}
      />
    </Card>
  );
};

export default ChangePassword;
