import React from "react";
import PropTypes from "prop-types";

// components
import { Button, Form, Input, Alert } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const UserForm = ({ handleSubmit, error, pending, user, fhidden, lhidden }) => {
  const [form] = Form.useForm();
  return (
    <Form editable={true} form={form} onFinish={handleSubmit}>
      <Form.Item
        hidden={fhidden}
        name="fname"
        rules={
          fhidden ? [] : [{ required: true, message: "Firstname is Required" }]
        }
      >
        <label>
          Firstname:
          <Input name="fname" placeholder="John" />
        </label>
      </Form.Item>
      <Form.Item
        name="lname"
        hidden={lhidden}
        rules={
          lhidden ? [] : [{ required: true, message: "Last name is Required" }]
        }
      >
        <label>
          Lastname:
          <Input name="lname" placeholder="Doe" />
        </label>
      </Form.Item>
      <Form.Item
        name="email"
        rules={
          user?.email
            ? [
                {
                  message: "Valid email is required!",
                  type: "email",
                },
              ]
            : [
                {
                  required: true,
                  message: "Valid email is required!",
                  type: "email",
                },
              ]
        }
      >
        <label>
          Email:
          <Input name="email" placeholder="user@gmail.com" />
        </label>
      </Form.Item>
      <Form.Item
        name="paypal_url"
        rules={
          user?.paypal_url
            ? [
                {
                  pattern: new RegExp(
                    "^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                  ),
                  message: "The url should start with paypal.me",
                },
              ]
            : [
                { required: true, message: "PayPal Url is Required" },
                {
                  pattern: new RegExp(
                    "^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                  ),
                  message: "The url should start with paypal.me",
                },
              ]
        }
      >
        <label>
          Paypal Url:
          <Input name="paypal_url" placeholder="paypal.me/johndoe" />
        </label>
      </Form.Item>
      <div className="flex-end flex-d-column">
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            className="float-right"
            icon={<CheckCircleOutlined />}
            loading={pending}
          >
            Submit
          </Button>
        </Form.Item>
        <div>
          {user?.message && (
            <Alert message={user?.message} type="success" showIcon closable />
          )}
          {error && <Alert message={error} type="error" showIcon closable />}
        </div>
      </div>
    </Form>
  );
};

UserForm.propTypes = {
  handleSubmit: PropTypes.func,
  error: PropTypes.any,
  pending: PropTypes.bool,
  user: PropTypes.any,
  fhidden: PropTypes.bool,
  lhidden: PropTypes.bool,
};

export default UserForm;
