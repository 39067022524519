import utils from "../../helpers";
import { INVITE_PENDING, INVITE_SUCCESS, INVITE_FAILURE } from "./types";

const { http, handleErrors } = utils;

export const invitePending = () => ({
  type: INVITE_PENDING,
});

export const inviteSuccess = (payload) => ({
  type: INVITE_SUCCESS,
  payload,
});

export const inviteFailure = (error) => ({
  type: INVITE_FAILURE,
  error,
});

export const invite = (user) => {
  return (dispatch) => {
    dispatch(invitePending());
    http()
      .put("/users/invitation", { accept_invitation: user })
      .then((response) =>
        dispatch(
          inviteSuccess({
            data: response?.data,
            token: response?.headers["access-token"],
            token_type: response?.headers["token-type"],
            uid: response?.headers["uid"],
            expiry: response?.headers["expiry"],
            client: response?.headers["client"],
          })
        )
      )
      .catch((error) =>
        dispatch(inviteFailure(handleErrors(error, "invitation_token")))
      );
  };
};
