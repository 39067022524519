import utils from "../../helpers";
import {
  CURRENT_USER_PENDING,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const currentUserPending = () => ({
  type: CURRENT_USER_PENDING,
});

export const currentUserSuccess = (payload) => ({
  type: CURRENT_USER_SUCCESS,
  payload,
});

export const currentUserFailure = (error) => ({
  type: CURRENT_USER_FAILURE,
  error,
});

export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch(currentUserPending());
    http()
      .get("/dashboards/me")
      .then((response) => dispatch(currentUserSuccess(response.data)))
      .catch((error) => dispatch(currentUserFailure(handleErrors(error))));
  };
};
