import {
  FOUNDATION_GIFT_ORDER_FAILURE,
  FOUNDATION_GIFT_ORDER_SUCCESS,
  FOUNDATION_GIFT_ORDER_PENDING,
} from "../../actions/payment/types";

const initialState = {
  message: null,
  pending: false,
  error: null,
};

const giftFoundationOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FOUNDATION_GIFT_ORDER_PENDING:
      return { pending: true };
    case FOUNDATION_GIFT_ORDER_SUCCESS:
      return {
        message: action.payload,
        pending: false,
        error: null,
      };
    case FOUNDATION_GIFT_ORDER_FAILURE:
      return {
        error: action.error,
        pending: false,
        message: null,
      };
    default:
      return state;
  }
};

export default giftFoundationOrderReducer;
