import React, { useState } from "react";
import Logo from "../../../common/Logo";
import Links from "./Links";
import Navbar from "../../../common/Menu";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Banner from "../../../common/Banner/Alert";
import { useAuth } from "../../../hooks/context";

const TopBar = () => {
  const [visible, setVisible] = useState();
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);
  const [header] = useState(["header hide-for-desktop hide-for-tablet"]);
  const { user } = useAuth();
  const message = `An email with instructions on how to confirm your new email address has been sent to ${user?.unconfirmed_email}. Please confirm the email address to use it.`;

  return (
    <header className={header.join(" ")}>
      <div className="flex flex-column-gap-1 header__button flex-ai-c">
        <Button type="link" onClick={showDrawer} icon={<MenuOutlined />} />
        <Logo />
      </div>
      <Navbar Logo={Logo} Links={Links} visible={visible} onClose={onClose} />
      <div style={{ marginTop: "10px" }} className="banner hide-for-desktop">
        {user.unconfirmed_email && <Banner type="info" message={message} />}
      </div>
    </header>
  );
};

export default TopBar;
