import {
  SIGNUP_PENDING,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
} from "../../actions/user/types";

const initialState = {
  user: null,
  pending: false,
  error: null,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_PENDING:
      return { pending: true };
    case SIGNUP_SUCCESS:
      return {
        user: action.payload,
        pending: false,
      };
    case SIGNUP_FAILURE:
      return {
        error: action.error.full_messages,
        pending: false,
      };
    default:
      return state;
  }
};

export default signupReducer;
