import utils from "../../helpers";
import {
  ACTIVE_BOARDS_PENDING,
  ACTIVE_BOARDS_SUCCESS,
  ACTIVE_BOARDS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const activeBoardsPending = () => ({
  type: ACTIVE_BOARDS_PENDING,
});

export const activeBoardsFailure = (error) => ({
  type: ACTIVE_BOARDS_FAILURE,
  error,
});

export const activeBoardsSuccess = (payload) => ({
  type: ACTIVE_BOARDS_SUCCESS,
  payload,
});

export const activeBoards = () => {
  return (dispatch) => {
    dispatch(activeBoardsPending());
    http()
      .get("/fundboards")
      .then((response) =>
        dispatch(activeBoardsSuccess(response?.data.fundboards))
      )
      .catch((error) =>
        dispatch(activeBoardsFailure(handleErrors(error, "active_boards")))
      );
  };
};
