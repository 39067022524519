import {
  STRIPE_CONNECT_CREATE_SUCCESS,
  STRIPE_CONNECT_CREATE_FAILURE,
  STRIPE_CONNECT_CREATE_PENDING,
} from "../../actions/stripeConnect/types";

const initialState = {
  accountLink: null,
  pending: false,
  error: null,
};

const createStripeConnectReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_CONNECT_CREATE_PENDING:
      return { pending: true };
    case STRIPE_CONNECT_CREATE_SUCCESS:
      return {
        accountLink: action.payload,
        pending: false,
        error: null,
      };
    case STRIPE_CONNECT_CREATE_FAILURE:
      return {
        error: action.error,
        pending: false,
        accountLink: null,
      };
    default:
      return state;
  }
};

export default createStripeConnectReducer;
