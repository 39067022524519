import React from "react";
import Proptypes from "prop-types";

// components
import { Table, Button, Card } from "antd";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import Dashboard from "../../../../Dashboard";
import columns from "./columns";

const Payouts = ({
  refresh,
  pending,
  pPending,
  onPay,
  membershipIds,
  isAdmin,
  user,
  data,
  rowSelection,
}) => (
  <Dashboard>
    <h3 className="admin-payouts-title">Send Payouts</h3>
    <Card className="admin-payouts">
      <div className="flex flex-jc-fe flex-row-gap-2">
        <Button
          type="dashed"
          icon={<SyncOutlined />}
          onClick={refresh}
          loading={pending || pPending}
        >
          Refresh
        </Button>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={onPay}
          loading={pending || pPending}
          disabled={!membershipIds?.length}
        >
          Mark as Paid
        </Button>
      </div>
      <br />
      <Table
        rowKey={(x) => x.email}
        columns={columns(isAdmin, user)}
        dataSource={data?.filter((m) => m.left_on !== null)}
        loading={pending}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        className="admin-payouts"
        showSorterTooltip
      />
    </Card>
  </Dashboard>
);

Payouts.propTypes = {
  refresh: Proptypes.func,
  pending: Proptypes.bool,
  pPending: Proptypes.bool,
  onPay: Proptypes.func,
  membershipIds: Proptypes.array,
  isAdmin: Proptypes.func,
  user: Proptypes.object,
  data: Proptypes.array,
  rowSelection: Proptypes.object,
};

export default Payouts;
