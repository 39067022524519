import {
  COMPLETE_BEGIN,
  COMPLETE_CONFIRM,
  COMPLETE_PRELOAD,
  COMPLETE_PAYMENT,
} from "./types";

const completeBegin = () => ({
  type: COMPLETE_BEGIN,
});

const completeConfirm = () => ({
  type: COMPLETE_CONFIRM,
});

const completePreload = () => ({
  type: COMPLETE_PRELOAD,
});

const completePayment = () => ({
  type: COMPLETE_PAYMENT,
});

export const completeTransaction = () => {
  return (dispatch) => {
    dispatch(completeBegin());
    dispatch(completeConfirm());
    dispatch(completePreload());
    dispatch(completePayment());
  };
};
