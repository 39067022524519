import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { Form } from "antd";
import LoginForm from "./LoginForm";
import { login } from "../../actions/user/login";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";

// hooks
import { useVerifyLogin } from "../../hooks/authentication";

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  const { pending, user, error } = useSelector((state) => state?.login);
  const loginError = useVerifyLogin(user, pending, error, history);

  const handleSubmit = (data) => dispatch(login(data));

  function changePageHandler() {
    history.push("/reset-password");
  }

  return (
    <>
      <GuestTopBar />
      <div className="login flex-jc-c flex-ai-c">
        <div className="login-form">
          <h3 className="login-form__header title">
            Welcome to your Gifting Board
          </h3>
          <LoginForm
            changePageHandler={changePageHandler}
            onClick={handleSubmit}
            pending={pending}
            errors={loginError}
            form={form}
          />
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  user: PropTypes.object,
};

export default Login;
