import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { Button, Alert, Card } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Dashboard from "../../index";
import Summary from "./Summary";

// actions
import { completeTransaction } from "../../../../actions/payment/endPayment";

// hooks
import {
  useCompleteJoinBoard,
  useConfirmPayment,
} from "../../../../hooks/payment";

// helpers
import storage from "../../../../helpers";

const { getCart, clearCart, getType } = storage;

const GiftCheckout = () => {
  const history = useHistory();
  const cart = getCart();
  const dispatch = useDispatch();

  // getting redux state
  const { payment, error: paymentError, pending: paymentPending } = useSelector(
    (state) => state?.confirmPayment
  );

  const { preload, error: preloadError, pending: preloadPending } = useSelector(
    (state) => state?.preloadPay
  );

  // local state
  const [paymentMethod] = useState("preload");

  const handleCancel = () => {
    if (getType() === "invite") {
      history.push("/invites");
    } else {
      history.push("/gift-order");
    }
    clearCart();
  };

  const handleChangeCart = () => {
    dispatch(completeTransaction());
    history.push("/gift-order");
  };

  const [, handleSubmit] = useConfirmPayment(
    null,
    null,
    paymentMethod,
    getCart()?.boardInfo,
    getType()
  );

  useCompleteJoinBoard(payment || preload, preloadError || paymentError);
  useEffect(() => !cart?.boardInfo && history.push("/gift-order"), []);
  return (
    <Dashboard>
      <div className="gift-wrapper">
        <Card className="gift-payment" size="small">
          <h3 className="element-header">Checkout</h3>
          <Summary cart={cart} handleChangeCart={handleChangeCart} />
          <div className="flex flex-jc-sb gift-payment-checkout-btn">
            <Button
              onClick={handleCancel}
              type="ghost"
              className="btn-outline-primary"
              icon={<CloseCircleOutlined />}
              disabled={paymentPending}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="primary"
              icon={<CheckCircleOutlined />}
              disabled={!(getCart()?.boardInfo && paymentMethod)}
              loading={paymentPending || preloadPending}
            >
              Checkout
            </Button>
          </div>
          <div className="gift-payment-errors">
            {(preloadError || paymentError) && (
              <Alert
                message={paymentError || preloadError}
                type="error"
                closable
                showIcon
              />
            )}
            {(payment || preload) && (
              <Alert
                message={payment || preload?.message}
                type="success"
                closable
                showIcon
              />
            )}
          </div>
        </Card>
      </div>
    </Dashboard>
  );
};

export default GiftCheckout;
