import { useEffect, useState } from "react";

import helpers from "../helpers";

const { saveItem, reload } = helpers;

export const useVerifyLogin = (user, pending, error, history, path) => {
  const [outputMesssage, setMessage] = useState();
  useEffect(() => {
    if (user) {
      saveItem("token", user?.token);
      saveItem("token_type", user?.token_type);
      saveItem("client", user?.client);
      saveItem("expiry", user?.expiry);
      saveItem("uid", user?.uid);
      if (path) {
        history.push(path);
      } else {
        history.push("/home");
        reload(history);
      }
    } else if (error) {
      setMessage(error);
    }
  }, [user, pending, error]);
  return outputMesssage;
};
