import React from "react";
import Proptypes from "prop-types";

// components
import { Table, Card } from "antd";

import Dashboard from "../../../../Dashboard";
import columns from "./columns";

const Accounts = ({
  pending,
  pPending,
  data,
  onDelete,
  value,
  setValue,
  filter,
  numOfRows,
  setNumOfRows,
}) => {
  const pageSizeArr = numOfRows.split(" ");
  return (
    <Dashboard>
      <h3 className="admin-payouts-title">User Accounts - {data?.length}</h3>
      <Card className="admin-payouts">
        <div className="flex flex-jc-fe flex-row-gap-2"></div>
        <br />
        <Table
          rowKey={(x) => x.id}
          columns={columns(value, setValue, onDelete, numOfRows, setNumOfRows)}
          dataSource={filter(data)}
          loading={pending || pPending}
          className="admin-payouts"
          showSorterTooltip
          pagination={{
            pageSize: pageSizeArr[0],
            position: ["topLeft", "bottomLeft"],
          }}
        />
      </Card>
    </Dashboard>
  );
};

Accounts.propTypes = {
  pending: Proptypes.bool,
  pPending: Proptypes.bool,
  data: Proptypes.array,
  onDelete: Proptypes.func,
  value: Proptypes.string,
  setValue: Proptypes.func,
  filter: Proptypes.func,
  numOfRows: Proptypes.string,
  setNumOfRows: Proptypes.func,
};

export default Accounts;
