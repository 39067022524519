import React from "react";
import PropTypes from "prop-types";
import { Descriptions, Button, Card } from "antd";
import { EditOutlined } from "@ant-design/icons";

const ButtonEdit = (updateUserInfo) => {
  return (
    <Button
      type="primary"
      onClick={() => updateUserInfo(true)}
      icon={<EditOutlined />}
    >
      Update
    </Button>
  );
};

const UserProfile = ({ user, updateUserInfo }) => {
  return (
    <Card className="settings-payment" bordered title="Profile Information">
      <Descriptions
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        extra={ButtonEdit(updateUserInfo)}
      >
        <Descriptions.Item label="First Name">{user.fname}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{user.lname}</Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Paypal url">
          <Button
            target="_blank"
            type="link"
            disabled={!user?.paypal_url}
            href={user?.paypal_url}
            size="small"
          >
            {user?.paypal_url}
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
UserProfile.propTypes = {
  user: PropTypes.object,
  updateUserInfo: PropTypes.func,
};
export default UserProfile;
