import utils from "../../helpers";
import {
  NEW_PASSWORD_PENDING,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const newPasswordPending = () => ({
  type: NEW_PASSWORD_PENDING,
});

export const newPasswordSuccess = (payload) => ({
  type: NEW_PASSWORD_SUCCESS,
  payload,
});

export const newPasswordFailure = (error) => ({
  type: NEW_PASSWORD_FAILURE,
  error,
});

export const newPassword = ({
  password,
  confirmPassword,
  uidString,
  accessTokenString,
  clientString,
}) => {
  return (dispatch) => {
    dispatch(newPasswordPending());
    if (uidString || accessTokenString || clientString) {
      http()
        .put(
          "/auth/password",
          {
            password: password,
            password_confirmation: confirmPassword,
          },
          {
            headers: {
              client: clientString,
              "access-token": accessTokenString,
              uid: uidString,
            },
          }
        )
        .then((response) => {
          dispatch(newPasswordSuccess(response.data.message));
        })
        .catch((error) => dispatch(newPasswordFailure(handleErrors(error))));
    } else {
      http()
        .put("/auth/password", {
          password: password,
          password_confirmation: confirmPassword,
        })
        .then((response) => {
          dispatch(newPasswordSuccess(response.data.message));
        })
        .catch((error) => dispatch(newPasswordFailure(handleErrors(error))));
    }
  };
};
