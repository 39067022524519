import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// components
import { Button, Form, InputNumber, Card, Radio } from "antd";
import Dashboard from "../index";

//helpers
import storage from "../../../helpers";
import utils from "../../../helpers";

const ACTIVE = utils.showFoundationGift();

const { saveItems, getFoundation, saveItem } = storage;

const FoundationGiftAmountForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [amount, setAmount] = useState(getFoundation());

  const handleSubmit = () => {
    if (amount > 0) {
      saveItems("foundation_gift", amount, null, null, null, null);
      history.push("/foundation-checkout");
    }
  };

  const handleInputChange = (value) => setAmount(value);

  return (
    <Dashboard>
      <div className="preload-wrapper">
        <Card className="preload">
          <h3 className="element-header preload-title">Foundation Gift</h3>
          <Form
            name="basic"
            onFinish={handleSubmit}
            form={form}
            initialValues={{ amount }}
          >
            <label>
              Enter Amount (USD)
              <Form.Item
                name="amount"
                rules={[{ required: true, message: "Amount is required" }]}
              >
                <InputNumber
                  name="amount"
                  min={1}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </label>
            <div className="preload-btn flex-d-column flex-end">
              <Form.Item>
                <Button
                  type="primary"
                  name="preload-button"
                  htmlType="submit"
                  disabled={amount <= 0 || !ACTIVE}
                >
                  Proceed To Checkout
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    </Dashboard>
  );
};

const FoundationGiftRadioForm = ({ setShowOtherForm }) => {
  const [selected, setSelected] = useState("");
  const [proceed, setProceed] = useState(false);

  const onChange = (e) => {
    setSelected(e.target.value);
    saveItem("foundation_gift_source", e.target.value);
    setProceed(true);
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <Dashboard>
      <div className="preload-wrapper">
        <Card className="preload">
          <h3 className="element-header preload-title">
            How do you want to gift to the foundation?
          </h3>
          <Radio.Group onChange={(e) => onChange(e)} value={selected}>
            <Radio style={radioStyle} value="Preloaded Account">
              Account Balance
            </Radio>
            <Radio style={radioStyle} value="Paypal">
              Paypal
            </Radio>
          </Radio.Group>
          <div className="preload-btn flex-d-column flex-end">
            <Form.Item>
              <Button
                type="primary"
                name="preload-button"
                htmlType="submit"
                disabled={proceed ? false : true}
                onClick={() => setShowOtherForm(true)}
              >
                Proceed
              </Button>
            </Form.Item>
          </div>
        </Card>
      </div>
    </Dashboard>
  );
};
FoundationGiftRadioForm.propTypes = {
  setShowOtherForm: PropTypes.func,
};
const FoundationGift = () => {
  const [showOtherForm, setShowOtherForm] = useState(false);

  if (showOtherForm === false) {
    return <FoundationGiftRadioForm setShowOtherForm={setShowOtherForm} />;
  }
  return <FoundationGiftAmountForm />;
};
export default FoundationGift;
