import utils from "../../helpers";
import {
  GET_BOARD_PENDING,
  GET_BOARD_SUCCESS,
  GET_BOARD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getBoardPending = () => ({
  type: GET_BOARD_PENDING,
});

export const getBoardSuccess = (payload) => ({
  type: GET_BOARD_SUCCESS,
  payload,
});

export const getBoardFailure = (error) => ({
  type: GET_BOARD_FAILURE,
  error,
});
export const getBoards = (id) => {
  return (dispatch) => {
    dispatch(getBoardPending());
    http()
      .get(`/communities/${id}/fundboards`)
      .then((response) => dispatch(getBoardSuccess(response?.data?.fundboards)))
      .catch((error) => dispatch(getBoardFailure(handleErrors(error, "data"))));
  };
};
