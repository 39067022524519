import {
  LOGOUT_PENDING,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../../actions/user/types";

const initialState = {
  logout: null,
  pending: false,
  error: null,
};

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_PENDING:
      return { pending: true, logout: false };
    case LOGOUT_SUCCESS:
      return {
        logout: true,
        pending: false,
      };
    case LOGOUT_FAILURE:
      return {
        error: action.error,
        logout: false,
        pending: false,
      };
    default:
      return state;
  }
};

export default logoutReducer;
