import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// hooks
import { useVerifyLogin } from "../../hooks/authentication";

// actions
import { invite } from "../../actions/user/invite";

// components
import InviteForm from "./InviteForm";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import Wizard from "../../common/wizard";
import { Form } from "antd";

const Invite = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { invitation_token } = useParams();

  // redux state
  const { pending, user, error } = useSelector((state) => state?.invite);

  const inviteError = useVerifyLogin(user, pending, error, history, "/payment");

  const handleSubmit = (data) =>
    dispatch(invite({ ...data, invitation_token }));

  return (
    <>
      <GuestTopBar />
      <Wizard classes="hide-for-mobile" steps={["active", "progress"]} />
      <div className="invite flex-jc-c flex-ai-c">
        <div className="invite-form">
          <h3 className="invite-form__header title">
            Welcome to your Gifting Board
          </h3>
          <InviteForm
            onClick={handleSubmit}
            pending={pending}
            errors={inviteError}
            form={form}
          />
        </div>
      </div>
    </>
  );
};

export default Invite;
