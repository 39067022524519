import utils from "../../helpers";
import {
  INVITE_BOARD_PENDING,
  INVITE_BOARD_SUCCESS,
  INVITE_BOARD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const inviteBoardPending = () => ({
  type: INVITE_BOARD_PENDING,
});

export const inviteBoardSuccess = (payload) => ({
  type: INVITE_BOARD_SUCCESS,
  payload,
});

export const inviteBoardFailure = (error) => ({
  type: INVITE_BOARD_FAILURE,
  error,
});

export const inviteBoard = (data) => {
  return (dispatch) => {
    dispatch(inviteBoardPending());
    http()
      .post("/users/invite_to_board", { invite_params: data})
      .then((response) => dispatch(inviteBoardSuccess(response.data)))
      .catch((error) => dispatch(inviteBoardFailure(handleErrors(error))));
  };
}
