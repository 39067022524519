import {
  PROMOTIONS_PENDING,
  PROMOTIONS_SUCCESS,
  PROMOTIONS_FAILURE,
} from "../../actions/waitingList/types";

const initialState = {
  promotions: null,
  pending: false,
  error: null,
};

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROMOTIONS_PENDING:
      return { pending: true };
    case PROMOTIONS_SUCCESS:
      return {
        promotions: action.payload,
        pending: false,
      };
    case PROMOTIONS_FAILURE:
      return {
        error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
};

export default promotionsReducer;
