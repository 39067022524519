import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";

//helpers
import utils from "../../../helpers";

const { formatter, currencyToNumber } = utils;

const SummaryElement = ({ title, number, actual, pending }) => {
  return (
    <Card className="element-box" style={{ padding: 0 }} loading={pending}>
      <p className="label title">{title}</p>
      <p className="value">
        {number && formatter.format(currencyToNumber(number.toString()))}
      </p>
      <div className="flex element-box-footer">
        {title === "net payout" && (
          <span>Actual: {formatter.format(currencyToNumber(actual))}</span>
        )}
      </div>
    </Card>
  );
};

SummaryElement.propTypes = {
  title: PropTypes.string,
  number: PropTypes.any,
  actual: PropTypes.any,
  pending: PropTypes.bool,
};

export default SummaryElement;
