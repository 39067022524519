import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// components
import { Button, Card, Badge } from "antd";

// helpers
import utils from "../../../helpers";

const ACTIVE = utils.showEnterGift();

const EnterGift = ({ pending }) => {
  const history = useHistory();
  const handleGift = () => {
    history.push("/gift-order");
  };

  return (
    <Card style={{ padding: 0 }} className="element-box" loading={pending}>
      <h3 className="label title">Enter Gift</h3>
      <p className="element-description">Gift to board</p>
      <Button
        onClick={handleGift}
        type="ghost"
        className={ACTIVE && `btn-outline-primary dashboard-btn`}
        disabled={!ACTIVE}
      >
        Enter
      </Button>
    </Card>
  );
};

const Index = () =>
  ACTIVE ? (
    <EnterGift />
  ) : (
    <Badge.Ribbon text="Enter Gift is disabled temporarily" color="#047bf8">
      <EnterGift />
    </Badge.Ribbon>
  );

EnterGift.propTypes = {
  pending: PropTypes.bool,
};

export default Index;
