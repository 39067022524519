import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Table, Card } from "antd";
import Dashboard from "../index";

// actions
import { activeBoards as activeBoardsAction } from "../../../actions/activeBoards/getActiveBoards";

const columns = [
  {
    title: "Fundboard",
    dataIndex: "gift_in",
    key: "gift_in",
    render: (item) => `$${parseInt(item)} Fundboard`,
  },
  {
    title: "Potential",
    dataIndex: "full_potential",
    key: "full_potential",
    render: (value) => parseInt(value),
  },
];

const ActiveBoards = () => {
  const dispatch = useDispatch();
  const { activeBoards, pending } = useSelector((state) => state?.activeBoards);

  useEffect(() => {
    dispatch(activeBoardsAction());
  }, []);

  return (
    <Dashboard>
      <Card className="active-boards" size="small">
        <h3 className="element-header gift-title">Active Boards</h3>
        <Table
          dataSource={activeBoards}
          pagination={{ pageSize: 5 }}
          columns={columns}
          loading={pending}
          size="small"
        />
      </Card>
    </Dashboard>
  );
};

export default ActiveBoards;
