import React from "react";
import PropTypes from "prop-types";

// components
import { Button, Modal } from "antd";
import InviteForm from "./Form";

const FormModal = ({
  visible,
  showModal,
  onSubmit,
  pending,
  form,
  selectedFundBoard,
}) => {
  const onClick = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch((info) => info);
  };

  return (
    <Modal
      title={`Send invites for $${parseInt(selectedFundBoard)} Fund Board`}
      onCancel={showModal}
      visible={visible}
      okText="Submit"
      cancelText="Cancel"
      footer={[
        <Button key="submit" type="primary" loading={pending} onClick={onClick}>
          Submit
        </Button>,
      ]}
    >
      <InviteForm form={form} />
    </Modal>
  );
};

FormModal.propTypes = {
  showModal: PropTypes.func,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  pending: PropTypes.bool,
  form: PropTypes.any,
  selectedFundBoard: PropTypes.string,
};

export default FormModal;
