import utils from "../../helpers";
import {
  COMPLETE_PAYMENT_PENDING,
  COMPLETE_PAYMENT_SUCCESS,
  COMPLETE_PAYMENT_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

const completePaymentPending = () => ({
  type: COMPLETE_PAYMENT_PENDING,
});

const completePaymentSuccess = (payload) => ({
  type: COMPLETE_PAYMENT_SUCCESS,
  payload,
});

const completePaymentError = (error) => ({
  type: COMPLETE_PAYMENT_FAILURE,
  error,
});

export const completePayment = (customer) => {
  return (dispatch) => {
    dispatch(completePaymentPending());
    http()
      .put("/payments/customer", customer)
      .then(() => dispatch(completePaymentSuccess(customer)))
      .catch((error) => dispatch(completePaymentError(handleErrors(error))))
  };
};
