import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import helpers from "../../../../helpers";

const { reload } = helpers;

// components
import Accounts from "./admin";

import { Modal } from "antd";

const { confirm, success, error: errorModal } = Modal;

// actions
import { getAccounts } from "../../../../actions/accounts/get";
import { deleteAccount } from "../../../../actions/accounts/delete";

const UserAccounts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pending, accounts } = useSelector((state) => state?.getAccounts);

  const {
    pending: pPending,
    message,
    error,
  } = useSelector((state) => state?.deleteAccount);

  const [dataSource, setDataSource] = useState([]);

  const [value, setValue] = useState("");

  const [numOfRows, setNumOfRows] = useState("5 rows");

  const filter = (rows) => {
    return rows?.filter((row) => row.uid.includes(value));
  };

  useEffect(() => {
    dispatch(getAccounts());
  }, []);

  useEffect(() => {
    accounts && setDataSource(accounts);
  }, [accounts]);

  useEffect(() => {
    message &&
      success({
        content: (
          <div>
            <p className="custom-modal-message">{message}</p>
          </div>
        ),

        onOk() {
          reload(history);
        },
      });
  }, [message]);

  useEffect(() => {
    error &&
      errorModal({
        content: (
          <div>
            <p className="custom-modal-message">{error}</p>
          </div>
        ),
        onOk() {},
      });
  }, [error]);

  const refresh = () => dispatch(getAccounts());

  const onDelete = ({ email, id }) => {
    confirm({
      content: (
        <div>
          <p className="custom-modal-message">
            Are you sure you want to deactivate account with email {email} ?
          </p>
        </div>
      ),
      onCancel() {},
      onOk() {
        dispatch(deleteAccount(id));
      },
    });
  };

  return (
    <Accounts
      pPending={pPending}
      refresh={refresh}
      pending={pending}
      onDelete={onDelete}
      data={dataSource}
      value={value}
      setValue={setValue}
      filter={filter}
      numOfRows={numOfRows}
      setNumOfRows={setNumOfRows}
    />
  );
};
export default UserAccounts;
