import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

// components
import { Menu } from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  CreditCardOutlined,
  UserOutlined,
  OrderedListOutlined,
  BugOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

// actions
import { logout as logoutAction } from "../../../../actions/user/logout";

// hooks
import { useAuth } from "../../../../hooks/context";

import helpers from "../../../../helpers";

const { reload } = helpers;

const paths = {
  "/home": 1,
  "/admin/board": 2,
  "/payouts": 4,
  "/settings": 5,
  "/faq": 6,
  "/report-bug": 7,
  "/admin/user-account": 3,
};

const Links = ({ classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const logout = useSelector((state) => state?.logout);
  const { pathname } = useLocation();
  const [selected] = useState([(paths[pathname] || 1).toString()]);
  const isAdmin = helpers.isAdmin(user);

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  useEffect(() => {
    if (logout?.logout) {
      history.push("/");
      reload(history);
    }
  }, [logout]);

  const handleActiveLink = ({ key }, history) => {
    key == 1 && history.push("/home");
    key == 2 && history.push("/admin/board");
    key == 3 && history.push("/admin/user-account");
    key == 4 && history.push("/payouts");
    key == 5 && history.push("/settings");
    key == 6 && history.push("/faq");
    key == 7 && history.push("/report-bug");
    key == 8 && handleLogout();
  };

  return (
    <div className={classes}>
      <Menu
        onClick={(evt) => handleActiveLink(evt, history)}
        selectedKeys={selected}
        mode="inline"
      >
        <Menu.Item key="1" icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        {isAdmin && (
          <>
            <Menu.Item key="2" icon={<UserOutlined />}>
              Manage Boards
            </Menu.Item>
            <Menu.Item key="3" icon={<OrderedListOutlined />}>
              User Accounts
            </Menu.Item>
          </>
        )}
        <Menu.Item key="4" icon={<CreditCardOutlined />}>
          Payouts
        </Menu.Item>
        <Menu.Item key="5" icon={<SettingOutlined />}>
          Settings
        </Menu.Item>
        <Menu.Item key="6" icon={<QuestionCircleOutlined />}>
          FAQ
        </Menu.Item>
        <Menu.Item key="7" icon={<BugOutlined />}>
          Report Issue
        </Menu.Item>
        <Menu.Item key="8" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
};

Links.propTypes = {
  classes: PropTypes.string,
  icons: PropTypes.array,
  currentUser: PropTypes.object,
};

export default Links;
