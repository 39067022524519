import React from "react";
import PropTypes from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

const Summary = ({ preload, handleChangeCart }) => (
  <div className="preload-summary">
    <h3 className="element-header">Preload Summary</h3>
    <p>The amount below will be saved to your Preload account</p>
    <h3>Total = ${preload}</h3>
    <div className="preload-btn">
      <Button icon={<EditOutlined />} onClick={handleChangeCart} type="primary">
        Change
      </Button>
    </div>
  </div>
);

Summary.propTypes = {
  handleChangeCart: PropTypes.func,
  preload: PropTypes.string,
};

export default Summary;
