import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Input } from "antd";

const LoginForm = ({ onClick, pending, errors, form }) => {
  return (
    <>
      <Form form={form} onFinish={onClick}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Valid email is required!",
              type: "email",
            },
          ]}
        >
          <label>
            Email:
            <Input name="email" placeholder="user@gmail.com" />
          </label>
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required!",
            },
          ]}
        >
          <label>
            Password:
            <Input.Password placeholder="******" name="password" />
          </label>
        </Form.Item>
        <Form.Item>
          <a className="login-forgot-password" href="/#/reset-password">
            Forgot password
          </a>
        </Form.Item>
        <Form.Item>
          <div className="flex flex-jc-fe">
            <Button loading={pending} type="primary" htmlType="submit">
              Login
            </Button>
          </div>
        </Form.Item>
      </Form>
      <div className="login-errors">
        {errors && !pending && (
          <Alert message={errors} type="error" closable showIcon />
        )}
      </div>
      <div className="login-form__signup flex flex-jc-c flex-ai-c">
        Need an account Sign up,
        <Button type="link" href="https://ourgivingboard.com/" size="small">
          here
        </Button>
      </div>
    </>
  );
};

LoginForm.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.object,
  errors: PropTypes.object,
  pending: PropTypes.bool,
  changePageHandler: PropTypes.func,
  form: PropTypes.any,
};

export default LoginForm;
