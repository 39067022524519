import React from "react";

// components
import { Tabs } from "antd";
import Dashboard from "../../../Dashboard";
import Invite from "./Invite";
import ActiveLevel from "./activelevel";
import { Card } from "antd";

const { TabPane } = Tabs;

const FundBoardList = () => {
  return (
    <Dashboard>
      <div className="admin-board">
        <h3 className="title element-header"> Manage Boards </h3>
        <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Invite" key="1">
              <Invite />
            </TabPane>
            <TabPane tab="Foundation Gift " key="2">
              <ActiveLevel />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </Dashboard>
  );
};

export default FundBoardList;
