import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Alert } from "antd";

const SignUpForm = ({ pending, errors, onClick, form }) => (
  <Form name="signup" onFinish={onClick} scrollToFirstError form={form}>
    <Form.Item
      name="fname"
      rules={[
        {
          required: true,
          message: "Please input your First Name!",
        },
      ]}
    >
      <label>
        First Name:
        <Input placeholder="First Name" name="fname" />
      </label>
    </Form.Item>
    <Form.Item
      name="lname"
      rules={[
        {
          required: true,
          message: "Please input your Last Name!",
        },
      ]}
    >
      <label>
        Last Name:
        <Input placeholder="Last Name" name="lname" />
      </label>
    </Form.Item>
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: "Please input your email",
        },
        {
          type: "email",
          message: "The input is not valid E-mail!",
        },
      ]}
    >
      <label>
        Email:
        <Input placeholder="Email" name="email" />
      </label>
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          validator: (_, value) =>
            value?.length >= 6
              ? Promise.resolve()
              : Promise.reject(
                  "Password should be greater than or equal to 6 characters!"
                ),
        },
        {
          required: true,
          message: "Please input your password!",
        },
      ]}
      hasFeedback
    >
      <label>
        Password:
        <Input.Password placeholder="******" name="password" />
      </label>
    </Form.Item>

    <Form.Item
      name="password_confirmation"
      dependencies={["password"]}
      hasFeedback
      rules={[
        {
          required: true,
          message: "Please confirm your password!",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              "The two passwords that you entered do not match!"
            );
          },
        }),
      ]}
    >
      <label>
        Confirm Password:
        <Input.Password placeholder="******" name="password_confirmation" />
      </label>
    </Form.Item>
    <div className="flex flex-jc-fe">
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={pending}>
          Sign Up
        </Button>
      </Form.Item>
    </div>
    {errors && !pending && (
      <Alert type="error" message={errors} closable showIcon />
    )}
  </Form>
);

SignUpForm.propTypes = {
  onClick: PropTypes.func,
  errors: PropTypes.object,
  pending: PropTypes.bool,
  form: PropTypes.any,
};

export default SignUpForm;
