import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// hooks
import { useVerifyLogin } from "../../hooks/authentication";

// actions
import { signup } from "../../actions/user/signup";

// components
import SignUpForm from "./SignupForm";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import { Form } from "antd";

const Signup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // redux state
  const { pending, user, error } = useSelector((state) => state?.signUp);

  const signupError = useVerifyLogin(user, pending, error, history);

  const handleSubmit = (data) => dispatch(signup(data));

  return (
    <>
      <GuestTopBar />
      <div className="signup flex-jc-c flex-ai-c">
        <div className="signup-form">
          <h3 className="signup-form__header title">
            Gifting Board Registration
          </h3>
          <SignUpForm
            onClick={handleSubmit}
            pending={pending}
            errors={signupError}
            form={form}
          />
        </div>
      </div>
    </>
  );
};

export default Signup;
