import {
  GET_ACTIVELEVEL_PENDING,
  GET_ACTIVELEVEL_SUCCESS,
  GET_ACTIVELEVEL_FAILURE,
} from "../../actions/activeLevel/types";

const initialState = {
  data: null,
  pending: false,
  error: null,
};

const getActiveLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVELEVEL_PENDING:
      return { pending: true };
    case GET_ACTIVELEVEL_SUCCESS:
      return {
        data: action.payload,
        pending: false,
        error: null,
      };
    case GET_ACTIVELEVEL_FAILURE:
      return {
        error: action.error,
        pending: false,
        data: null,
      };
    default:
      return state;
  }
};

export default getActiveLevelReducer;
