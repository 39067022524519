import {
  REVERSE_TRANSFER_PENDING,
  REVERSE_TRANSFER_SUCCESS,
  REVERSE_TRANSFER_FAILURE,
} from "../../actions/board/types";

const initialState = {
  data: null,
  pending: false,
  error: null,
};

const reverseTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case REVERSE_TRANSFER_PENDING:
      return { pending: true };
    case REVERSE_TRANSFER_SUCCESS:
      return {
        data: action?.payload,
        pending: false,
        error: null,
      };
    case REVERSE_TRANSFER_FAILURE:
      return {
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};

export default reverseTransferReducer;
