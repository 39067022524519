import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// components
import { Button, Form, InputNumber, Card } from "antd";
import Dashboard from "../index";

//helpers
import storage from "../../../helpers";
import utils from "../../../helpers";

const ACTIVE =utils.showPreload();

const { getPreload, saveItems } = storage;

const PreloadGift = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [amount, setAmount] = useState(getPreload());

  const handleSubmit = () => {
    if (amount > 0) {
      saveItems("preload", amount, null, null, null, null);
      history.push("/preload-checkout");
    }
  };

  const handleInputChange = (value) => setAmount(value);

  return (
    <Dashboard>
      <div className="preload-wrapper">
        <Card className="preload">
          <h3 className="element-header preload-title">Preload Gift</h3>
          <Form
            name="basic"
            onFinish={handleSubmit}
            form={form}
            initialValues={{ amount }}
          >
            <label>
              Enter Amount (USD)
              <Form.Item
                name="amount"
                rules={[{ required: true, message: "Amount is required" }]}
              >
                <InputNumber
                  name="amount"
                  min={1}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </label>
            <div className="preload-btn flex-d-column flex-end">
              <Form.Item>
                <Button
                  type="primary"
                  name="preload-button"
                  htmlType="submit"
                  disabled={amount <= 0 || !ACTIVE}
                >
                  Proceed To Checkout
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    </Dashboard>
  );
};

export default PreloadGift;
