import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card } from "antd";

const Invites = ({ pending }) => {
  const history = useHistory();
  const handlePreload = () => {
    history.push("/invites");
  };

  return (
    <Card
      style={{ padding: 0 }}
      className="element-box  element-box-invite"
      loading={pending}
    >
      <h3 className="label title">Invites</h3>
      <p className="element-description">Invites to the fundboards</p>
      <Button
        type="ghost"
        className="btn-outline-primary"
        onClick={handlePreload}
      >
        View Invites
      </Button>
    </Card>
  );
};

Invites.propTypes = {
  pending: PropTypes.bool,
};

export default Invites;
