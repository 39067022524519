import utils from "../../helpers";
import {
  FOUNDATION_GIFT_ORDER_FAILURE,
  FOUNDATION_GIFT_ORDER_SUCCESS,
  FOUNDATION_GIFT_ORDER_PENDING,
} from "./types";

const { http, handleErrors } = utils;

const foundationGiftOrderPending = () => ({
  type: FOUNDATION_GIFT_ORDER_PENDING,
});

const foundationGiftOrderSuccess = (payload) => ({
  type: FOUNDATION_GIFT_ORDER_SUCCESS,
  payload,
});

const foundationGiftOrderError = (error) => ({
  type: FOUNDATION_GIFT_ORDER_FAILURE,
  error,
});

export const foundationGiftPayPalOrder = (data) => {
  if (data.source === "USER_ACCOUNT") {
    const payload = {
      source: "user_account",
      type: "foundation_gift",
      amount: data.amount,
    };
    return (dispatch) => {
      dispatch(foundationGiftOrderPending());
      http()
        .post("/payments/paypal_preload", payload)
        .then((response) =>
          dispatch(foundationGiftOrderSuccess(response?.data?.message))
        )
        .catch((error) =>
          dispatch(foundationGiftOrderError(handleErrors(error)))
        );
    };
  }
  const payload = {
    order_id: data.id,
    type: "foundation_gift",
    source: "paypal",
  };
  return (dispatch) => {
    dispatch(foundationGiftOrderPending());
    http()
      .post("/payments/paypal_preload", payload)
      .then((response) =>
        dispatch(foundationGiftOrderSuccess(response?.data?.message))
      )
      .catch((error) =>
        dispatch(foundationGiftOrderError(handleErrors(error)))
      );
  };
};
