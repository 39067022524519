import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card } from "antd";
import PaypalCheckout from "../Paypal/checkout";

// actions
import { foundationGiftPayPalOrder } from "../../../../actions/payment/foundationGiftPaypalOrder";

// hooks
import { useCompleteJoinBoard } from "../../../../hooks/payment";
import { useAuth } from "../../../../hooks/context";

// helpers
import storage from "../../../../helpers";
import { getFoundation } from "../../../../helpers/localStorage";

const { clearCart } = storage;

const PaypalCheckoutForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [paypalError, setPaypalError] = useState();
  const [paypalCancel, setPaypalCancel] = useState();
  const [paypalLoading, setPaypalLoading] = useState(false);
  const { user } = useAuth();

  const { message, error, pending } = useSelector(
    (state) => state?.giftFoundationOrder
  );

  useCompleteJoinBoard(message);

  const handleCancel = () => {
    clearCart();
    history.push("/foundation-gift");
  };

  const onErrorClose = () => setPaypalError();
  const onCancelClose = () => setPaypalCancel();

  const handlePayPalError = () => {
    setPaypalLoading(false);
    setPaypalError("Money not transferred to our account, please try again");
  };

  const handlePayPalCancel = () => {
    setPaypalLoading(false);
    paypalCancel("The transaction was cancelled");
  };

  const handleApprove = (id) => {
    setPaypalLoading(true);
    const payload = {
      id,
      source: "PAYPAL",
    };
    dispatch(foundationGiftPayPalOrder(payload));
  };

  const handlePending = () => setPaypalLoading(true);

  return (
    <div className="preload-payment">
      <h3 className="element-header">Billing Details</h3>
      <Card className="gift-payment-container" bordered={false}>
        <PaypalCheckout
          amount={getFoundation()}
          user={user}
          onError={handlePayPalError}
          onCancel={handlePayPalCancel}
          handleApprove={handleApprove}
          handlePending={handlePending}
        />
      </Card>
      <div className="flex flex-jc-sb gift-payment-checkout-btn">
        <Button
          onClick={handleCancel}
          type="ghost"
          className="btn-outline-primary"
          icon={<CloseCircleOutlined />}
          loading={!error && (paypalLoading || pending)}
        >
          Cancel
        </Button>
      </div>
      <div className="gift-payment-errors">
        {error && <Alert message={error} type="error" closable showIcon />}
        {paypalCancel && (
          <Alert
            message={paypalCancel}
            type="info"
            closable
            showIcon
            onClose={onCancelClose}
          />
        )}
        {paypalError && (
          <Alert
            message={paypalError}
            type="error"
            closable
            showIcon
            onClose={onErrorClose}
          />
        )}
      </div>
    </div>
  );
};

export default PaypalCheckoutForm;
