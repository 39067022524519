import {
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
} from "../../actions/accounts/types";

const initialState = {
  message: null,
  pending: false,
  error: null,
};

const deleteAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ACCOUNT_PENDING:
      return { pending: true };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        message: action.payload,
        pending: false,
        error: null,
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        error: action.error,
        pending: false,
        message: null,
      };
    default:
      return state;
  }
};

export default deleteAccountReducer;
