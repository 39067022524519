import { combineReducers } from "redux";

//Stripe Connect
import getStripeConnectReducer from "./stripeConnect/get";
import createStripeConnectReducer from "./stripeConnect/create";

// user
import invitationReducer from "./user/invitation";
import loginReducer from "./user/login";
import updateUserReducer from "./user/updateUser";
import currentUserReducer from "./user/currentUser";
import forgotPasswordReducer from "./user/forgotPassword";
import newPasswordReducer from "./user/newPassword";
import signupReducer from "./user/signup";
import logoutReducer from "./user/logout";

// payment
import createPaymentReducer from "./payment/createPayment";
import confirmPaymentReducer from "./payment/confirmPayment";
import beginPaymentReducer from "./payment/beginPayment";
import completePaymentReducer from "./payment/completePayment";
import preloadPayPaymentReducer from "./payment/paymentThroughPreload";

//Accounts
import getAccountsReducer from "./accounts/get";
import deleteAccountReducer from "./accounts/delete";

// boards
import getBoardReducer from "./boards/get";
import deleteBoardReducer from "./boards/delete";
import updateBoardReducer from "./boards/update";
import createBoardReducer from "./boards/create";
import inviteBoardReducer from "./boards/invite";
import getInvitesReducer from "./boards/getInvite";

// communities
import getCommunitiesReducer from "./communities/get";
import deleteCommunityReducer from "./communities/delete";
import updateCommunityReducer from "./communities/update";
import createCommunityReducer from "./communities/create";

// waiting list
import waitingListReducer from "./waitingList/get";
import promotionsReducer from "./waitingList/promotions";

// active boards
import activeBoardsReducer from "./activeBoards/get";

// community stats
import communityStatsReducer from "./communityStats/get";
import statReducer from "./stats/get";
import billingDetailsReducer from "./billingDetails/get";

//paypal order
import paypalOrderReducer from "./payment/paypalOrder";
import giftFoundationOrderReducer from "./payment/giftFoundationPaypalOrder";

// payouts
import membershipReducer from "./boards/membership";
import payMembershipReducer from "./boards/pay";

//report issues
import issueReducer from "./reportIssues/report";

// transfer
import transferToAccountReducer from "./boards/transferToAccount";
import reverseTransferReducer from "./boards/reverse";

//active level
import getActiveLevelReducer from "./activeLevel/get";
import updateActiveLevelReducer from "./activeLevel/update";

export default combineReducers({
  invite: invitationReducer,
  login: loginReducer,
  currentUser: currentUserReducer,
  updateUser: updateUserReducer,
  createPayment: createPaymentReducer,
  confirmPayment: confirmPaymentReducer,
  completePayment: completePaymentReducer,
  beginPayment: beginPaymentReducer,
  forgotPassword: forgotPasswordReducer,
  newPassword: newPasswordReducer,
  boards: getBoardReducer,
  deleteBoard: deleteBoardReducer,
  updateBoard: updateBoardReducer,
  createBoard: createBoardReducer,
  communities: getCommunitiesReducer,
  deleteCommunity: deleteCommunityReducer,
  updateCommunity: updateCommunityReducer,
  createComminuty: createCommunityReducer,
  waitingList: waitingListReducer,
  activeBoards: activeBoardsReducer,
  communityStats: communityStatsReducer,
  stat: statReducer,
  billing: billingDetailsReducer,
  getStripeConnect: getStripeConnectReducer,
  createStripeConnect: createStripeConnectReducer,
  inviteBoard: inviteBoardReducer,
  getInvites: getInvitesReducer,
  preloadPay: preloadPayPaymentReducer,
  signUp: signupReducer,
  logout: logoutReducer,
  paypalOrder: paypalOrderReducer,
  giftFoundationOrder: giftFoundationOrderReducer,
  membership: membershipReducer,
  payMembership: payMembershipReducer,
  reportIssue: issueReducer,
  promotions: promotionsReducer,
  transferToAccount: transferToAccountReducer,
  reverse: reverseTransferReducer,
  getActiveLevel: getActiveLevelReducer,
  updateActiveLevel: updateActiveLevelReducer,
  deleteAccount: deleteAccountReducer,
  getAccounts: getAccountsReducer,
});
