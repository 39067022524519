import utils from "../../helpers";
import {
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const forgotPasswordPending = () => ({
  type: FORGOT_PASSWORD_PENDING,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
});

export const forgotPassword = ({ email }) => {
  return (dispatch) => {
    dispatch(forgotPasswordPending());
    http()
      .post("/auth/password", {
        email: email,
      })
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data.message));
      })
      .catch((error) => {
        dispatch(forgotPasswordFailure(handleErrors(error)));
      });
  };
};
