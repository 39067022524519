import utils from "../../helpers";
import {
  UPDATE_ACTIVELEVEL_PENDING,
  UPDATE_ACTIVELEVEL_SUCCESS,
  UPDATE_ACTIVELEVEL_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const updateActiveLevelPending = () => ({
  type: UPDATE_ACTIVELEVEL_PENDING,
});

export const updateActiveLevelSuccess = (payload) => ({
  type: UPDATE_ACTIVELEVEL_SUCCESS,
  payload,
});

export const updateActiveLevelFailure = (error) => ({
  type: UPDATE_ACTIVELEVEL_FAILURE,
  error,
});

export const updateActiveLevel = (data) => {
  return (dispatch) => {
    dispatch(updateActiveLevelPending());
    http()
      .put(`/fundboards/${data}`)
      .then((response) => {
        dispatch(updateActiveLevelSuccess(response.data.message));
      })
      .catch((error) =>
        dispatch(updateActiveLevelFailure(handleErrors(error)))
      );
  };
};
