import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/context";

const Protected = ({ component: Component, ...rest }) => {
  const { getToken } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

Protected.propTypes = {
  component: PropTypes.any,
};

export default Protected;
