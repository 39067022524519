import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import { Divider } from "antd";
import SummaryElement from "../SummaryContent";
import Community from "../Community";
import Dashboard from "../index";

// actions
import { communityStats as communityStatsAction } from "../../../actions/communityStats/getCommunityStats";
import { getStat } from "../../../actions/stats";
import { getBoardInvites } from "../../../actions/board/getInvites";

// hooks
import { useAuth } from "../../../hooks/context";

const Home = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const { stat, pending: pendingStat } = useSelector((state) => state?.stat);
  const { invites, pending: pendingInvites } = useSelector(
    (state) => state?.getInvites
  );

  useEffect(() => {
    dispatch(communityStatsAction());
    dispatch(getStat());
    dispatch(getBoardInvites());
  }, []);

  return (
    <Dashboard>
      <div className="dashboard-main">
        <div className="title dashboard-username hide-for-mobile">
          {user?.name}
        </div>
        <div className="dashboard-summary">
          <SummaryElement
            title="total potential"
            number={stat?.full_potential}
            pending={pendingInvites || pendingStat || user?.pending}
          />
          <SummaryElement
            title="total gifted"
            number={stat?.total_gifted}
            pending={pendingInvites || pendingStat || user?.pending}
          />
          <SummaryElement
            title="net payout"
            number={stat?.net_payout}
            actual={stat?.actual_balance}
            pending={pendingInvites || pendingStat || user?.pending}
          />
          <SummaryElement
            title="account balance"
            number={`${user?.account_balance}`}
            pending={pendingInvites || pendingStat || user?.pending}
          />
        </div>
        <Divider orientation="right" dashed />
        <Community
          invites={invites}
          pending={pendingInvites || pendingStat || user?.pending}
        />
      </div>
    </Dashboard>
  );
};

export default Home;
