import React from "react";
import { useSelector, useDispatch } from "react-redux";

// // stripe
// import { CardNumberElement } from "@stripe/react-stripe-js";
// import { useElements, useStripe } from "@stripe/react-stripe-js";

//components
import { Form } from "antd";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import Wizard from "../../common/wizard";
import PayForm from "./PayForm";

// hooks
import { useCompletePayment, usePaymentInfoSaved } from "../../hooks/payment";
import { useAuth } from "../../hooks/context";

// actions
import { createPayment } from "../../actions/payment/createPayment";

const CheckoutForm = () => {
  const [form] = Form.useForm();
  // const elements = useElements();
  const dispatch = useDispatch();
  // const stripe = useStripe();
  const { user } = useAuth();

  // redux state
  const {
    payment,
    error: createPaymentError,
    pending: createPaymentPending,
  } = useSelector((state) => state?.createPayment);
  const {
    complete,
    error: completeError,
    pending: completePending,
  } = useSelector((state) => state?.completePayment);

  const handleSubmit = (data) => {
    const userData = {
      email: user?.email,
      name: data?.name,
      phone: "00000",
    };
    // const cardElement = elements.getElement(CardNumberElement);
    dispatch(createPayment(null, null, userData));
  };

  useCompletePayment(payment);
  usePaymentInfoSaved(complete);

  return (
    <>
      <Wizard
        classes="hide-for-mobile"
        steps={["active", "active", "progress"]}
      />
      <PayForm
        form={form}
        handleSubmit={handleSubmit}
        stripe={null}
        pending={createPaymentPending}
        completePending={completePending}
        errors={createPaymentError?.error?.message || completeError}
      />
    </>
  );
};

const Payment = () => (
  <>
    <GuestTopBar />
    <CheckoutForm />
  </>
);

export default Payment;
