import React from "react";
import { Alert } from "antd";
import PropTypes from "prop-types";

const Banner = ({ type, message }) => {
  return (
    <Alert
      description={message}
      type={type}
      showIcon
      closable={true}
      banner={true}
    />
  );
};
Banner.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default Banner;
