import React from "react";

// components
import { Card } from "antd";
import Dashboard from "../../Dashboard";
import ReportBug from "./ReportBugForm";

//hooks
import { useAuth } from "../../../hooks/context";

const ReportIssue = () => {
  const { user } = useAuth();

  return (
    <Dashboard>
      <h3 className="bug-header">Report Issue</h3>
      <Card className="bug">
        <ReportBug user={user} />
      </Card>
    </Dashboard>
  );
};

export default ReportIssue;
