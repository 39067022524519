import utils from "../../helpers";
import {
  WAIT_LIST_PENDING,
  WAIT_LIST_SUCCESS,
  WAIT_LIST_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const waitingListPending = () => ({
  type: WAIT_LIST_PENDING,
});

export const waitingListSuccess = (payload) => ({
  type: WAIT_LIST_SUCCESS,
  payload,
});

export const waitingListFailure = (error) => ({
  type: WAIT_LIST_FAILURE,
  error,
});

export const waitingList = () => {
  return (dispatch) => {
    dispatch(waitingListPending());
    http()
      .get("/fundboards/wait_list")
      .then((response) =>
        dispatch(waitingListSuccess(response?.data.wait_lists))
      )
      .catch((error) => dispatch(waitingListFailure(handleErrors(error, "wait_lists"))));
  };
};
