import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// components
import { Button, Form, Card, Radio, Alert } from "antd";
import { success } from "../../../../../common/Modals";
import { EditOutlined } from "@ant-design/icons";
//actions
import { getActiveLevel } from "../../../../../actions/activeLevel/get";
import { updateActiveLevel } from "../../../../../actions/activeLevel/update";

const RadioForm = ({ currentActiveLevel, options }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { message, pending, error } = useSelector(
    (state) => state?.updateActiveLevel
  );

  const oldActiveLevel = currentActiveLevel;

  const [selected, setSelected] = useState(oldActiveLevel);

  const onChange = (e) => {
    setSelected(e.target.value);
  };

  const update = () => {
    dispatch(updateActiveLevel(selected));
  };

  useEffect(() => {
    if (message) {
      success(message, history);
      history.push("/home");
    }
  }, [message]);

  return (
    <div className="preload-wrapper">
      <Card bordered={false} pending={pending} className="preload">
        <h3>Which level should foundation gift money go?</h3>
        <Radio.Group
          options={options}
          onChange={(e) => onChange(e)}
          value={selected}
        />
        <div className="preload-btn flex-d-column flex-end">
          <Form.Item>
            <Button
              type="primary"
              name="preload-button"
              htmlType="submit"
              disabled={selected === oldActiveLevel ? true : false}
              onClick={() => update()}
              icon={<EditOutlined />}
              loading={pending}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
        <div>
          {error && <Alert message={error} type="error" showIcon closable />}
        </div>
      </Card>
    </div>
  );
};
RadioForm.propTypes = {
  currentActiveLevel: PropTypes.number,
  options: PropTypes.array,
};
const CurrentActiveLevel = ({
  currentActiveLevel,
  pending,
  error,
  setShowForm,
}) => {
  const whereMoneyGoing = `The Gift Foundation money is currently going to level ${
    currentActiveLevel - 2
  }`;

  if (error) {
    return <Card bordered={false}>{error}</Card>;
  } else {
    return (
      <div>
        <Card
          bordered={false}
          loading={pending}
          className="gift-payment-container"
        >
          <h3>{whereMoneyGoing}</h3>
        </Card>
        <div className="flex flex-jc-fe gift-payment-checkout-btn">
          <Button
            onClick={() => setShowForm(true)}
            type="ghost"
            className="btn-outline-primary"
            icon={<EditOutlined />}
          >
            Change
          </Button>
        </div>
      </div>
    );
  }
};

const ActiveLevel = () => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);

  const { data, pending, error } = useSelector(
    (state) => state?.getActiveLevel
  );

  const options = [];

  const filteredResult = data?.fundboards.filter(
    (f) => f.id !== 1 && f.id !== 6 && f.id !== 2
  );

  filteredResult?.forEach((fundboard) => {
    const fundboardLevelAndId = {};
    fundboardLevelAndId.label = ` Level ${fundboard.level - 2}`;
    fundboardLevelAndId.value = fundboard.id;
    options.push(fundboardLevelAndId);
  });

  useEffect(() => {
    dispatch(getActiveLevel());
  }, []);

  if (showForm) {
    return (
      <RadioForm
        options={options}
        setShowForm={setShowForm}
        currentActiveLevel={data?.active_fundboard_id}
      />
    );
  }

  return (
    <CurrentActiveLevel
      currentActiveLevel={data?.active_fundboard_id}
      pending={pending}
      error={error}
      setShowForm={setShowForm}
    />
  );
};
export default ActiveLevel;
