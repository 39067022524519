import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Tooltip } from "antd";

// helpers
import helpers from "../../../helpers";
const { formatDate, getStatus,calcPositions } = helpers;

// components
import { SyncOutlined } from "@ant-design/icons";
import { Table, Card, Button } from "antd";
const { Column} = Table;
import Dashboard from "../index";
import { components, expandedRowRender } from "./columns";

// actions
import { waitingList as waitingListAction } from "../../../actions/waitingList/getWaitingList";
import { getPromotions as getpromotionsAction } from "../../../actions/waitingList/promotions";

const WaitingList = () => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState([]);
  const [len, setLen] = useState(4);

  const { waitingList, pending: waitinglistPending } = useSelector(
    (state) => state?.waitingList
  );

  const { promotions, pending: promotionsPending } = useSelector(
    (state) => state?.promotions
  );

  
  useEffect(() => {
    dispatch(waitingListAction());
  }, []);

  const getPromotions = (id) => {
    setSelectedId([id]);
    dispatch(getpromotionsAction(id));
  };

  const lenHandler = (value) => {
    setLen(value);
  };

  const collapseRow = () => {
    setSelectedId([]);
  };

  const expandCollapse = (record) => ({
    onClick: () => {
      selectedId === record.id ? collapseRow() : setSelectedId(record.id);
      setLen(4);
    },
  });

  const filterByMembershipType = (membership) =>
    waitingList?.filter((x) => x.membership_type === membership);

  const refresh = () => {
    dispatch(waitingListAction());
    const id = Number(selectedId);
    id && getPromotions(id);
  };

  return (
    <Dashboard>
      <Card className="waiting-list" size="small">
        <div className="flex flex-jc-sb">
          <h3 className="element-header gift-title">Waiting List</h3>
          <Button
            type="dashed"
            loading={waitinglistPending}
            onClick={refresh}
            icon={<SyncOutlined />}
          >
            Refresh
          </Button>
        </div>
        <Table
          expandedRowRender={() =>
            expandedRowRender(promotions, promotionsPending, lenHandler, len)
          }
          onExpand={(expanded, record) =>
            expanded ? getPromotions(record.id) : collapseRow()
          }
          dataSource={filterByMembershipType("purchase")}
          onRow={(record) => expandCollapse(record)}
          rowKey={"id"}
          expandedRowKeys={[selectedId]}
          pagination={{ pageSize: 5 }}
          components={components}
          expandable={{ expandRowByClick: true }}
          loading={waitinglistPending}
          size="small"
        >
          <Column
            title="Fundboard"
            dataIndex="gift_in"
            key="gift_in"
            className="text-0-8"
            render={(value) => `$${parseInt(value)}`}
          ></Column>
          <Column
            title="Potential"
            dataIndex="full_potential"
            key="full_potential"
            render={(value) => `$${parseInt(value)}`}
          ></Column>
          <Column
            title="Position"
            dataIndex="position"
            key="position"
            render={(_position,purchase) => calcPositions(purchase)}
          ></Column>

          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, purchase) => {
              return purchase.fully_gifted === true
                ? "Promoted"
                : getStatus(status);
            }}
          ></Column>

          <Column
            title="Joined On"
            dataIndex="joined_on"
            key="joined_on"
            showTitle={false}
            render={(value) => (
              <>
                <Tooltip placement="topLeft" title={formatDate(value)}>
                  {formatDate(value)}
                </Tooltip>
              </>
            )}
          ></Column>
        </Table>
      </Card>
    </Dashboard>
  );
};

export default WaitingList;
