import {
  REPORT_ISSUE_PENDING,
  REPORT_ISSUE_SUCCESS,
  REPORT_ISSUE_FAILURE,
} from "../../actions/reportIssues/types";

const initialState = {
  reported: false,
  pending: false,
  error: null,
};

const issueReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_ISSUE_PENDING:
      return { pending: true };
    case REPORT_ISSUE_SUCCESS:
      return {
        reported: action.payload,
        pending: false,
        error: null,
      };
    case REPORT_ISSUE_FAILURE:
      return {
        error: action.payload,
        pending: false,
        reported: false,
      };
    default:
      return state;
  }
};

export default issueReducer;
