import {
  TRANSFER_TO_ACCOUNT_PENDING,
  TRANSFER_TO_ACCOUNT_SUCCESS,
  TRANSFER_TO_ACCOUNT_FAILURE,
} from "../../actions/board/types";

const initialState = {
  data: null,
  pending: false,
  error: null,
};

const transferToAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSFER_TO_ACCOUNT_PENDING:
      return { pending: true };
    case TRANSFER_TO_ACCOUNT_SUCCESS:
      return {
        data: "done",
        pending: false,
        error: null,
      };
    case TRANSFER_TO_ACCOUNT_FAILURE:
      return {
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};

export default transferToAccountReducer;
