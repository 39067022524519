import React from "react";
import Proptypes from "prop-types";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";

const SelectInput = ({ value, onRowChange }) => {
  const options = [
    { value: "5 rows" },
    { value: "10 rows" },
    { value: "20 rows" },
    { value: "25 rows" },
    { value: "50 rows" },
    { value: "100 rows" },
  ];
  const handleRowChange = (data) => {
    onRowChange(data);
  };
  return (
    <Select
      placeholder="Select rows per page"
      defaultValue={value}
      onChange={handleRowChange}
      options={options}
    />
  );
};

SelectInput.propTypes = {
  value: Proptypes.any,
  onRowChange: Proptypes.func,
};

const deactivateButton = (delFn, data) => {
  return (
    <Button
      type="danger"
      onClick={() => delFn(data)}
      icon={<DeleteOutlined />}
      disabled={data.deactivated}
    >
      Deactivate
    </Button>
  );
};

const SearchInput = ({ value, onChangeFn }) => (
  <Input
    placeholder="Search Email"
    value={value}
    onChange={(e) => {
      const currValue = e.target.value;
      onChangeFn(currValue);
    }}
  />
);

SearchInput.propTypes = {
  value: Proptypes.any,
  onChangeFn: Proptypes.func,
};

const columns = (value, setValue, onDelete, numOfRows, setNumOfRows) => [
  {
    title: <SearchInput value={value} onChangeFn={setValue} />,
    render: ({ uid }) => `${uid}`,
    filtered: true,
  },

  {
    title: <SelectInput value={numOfRows} onRowChange={setNumOfRows} />,
    render: (data) => {
      return deactivateButton(onDelete, data);
    },
  },
];

export default columns;
