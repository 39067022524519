export const showEnterGift = () =>
  process.env.REACT_APP_TURN_ON_ENTER_GIFT === "on";

export const showPreload = () => process.env.REACT_APP_TURN_ON_PRELOAD === "on";

export const showFoundationGift = () =>
  process.env.REACT_APP_TURN_ON_FOUNDATION_GIFT === "on";

export const showCommunityStatus = () =>
  process.env.REACT_APP_TURN_ON_COMMUNITY_STATUS === "on";
