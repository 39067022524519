import utils from "../../helpers";
import {
  GET_MEMBERSHIPS_PENDING,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getMembershipsPending = () => ({
  type: GET_MEMBERSHIPS_PENDING,
});

export const getMembershipsSuccess = (payload) => ({
  type: GET_MEMBERSHIPS_SUCCESS,
  payload,
});

export const getMembershipsFailure = (error) => ({
  type: GET_MEMBERSHIPS_FAILURE,
  error,
});

export const getMemberships = () => {
  return (dispatch) => {
    dispatch(getMembershipsPending());
    http()
      .get("/fundboard_memberships")
      .then((response) => dispatch(getMembershipsSuccess(response.data?.fundboard_memberships)))
      .catch((error) => dispatch(getMembershipsFailure(handleErrors(error))));
  };
};
