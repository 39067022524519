import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { Card } from "antd";
import NewPasswordForm from "./RecoverForm";
import GuestTopBar from "../Dashboard/TopBar/GuestTopBar";
import { newPassword } from "../../actions/user/newPassword";
import utils from "../../helpers";

const { getUrlParams } = utils;

const recoverPassword = () => {
  const history = useHistory();

  const uidString = decodeURIComponent(getUrlParams("uid"));
  const accessTokenString = getUrlParams("access-token");
  const clientString = getUrlParams("client");

  if (
    uidString === null ||
    accessTokenString === null ||
    clientString === null
  ) {
    history.push("/");
  }

  const headersData = {
    uidString,
    accessTokenString,
    clientString,
  };

  const dispatch = useDispatch();
  const { pending, error, success } = useSelector(
    (state) => state?.newPassword
  );

  const onSubmit = (data) => {
    dispatch(
      newPassword({
        ...data,
        ...headersData,
      })
    );
  };

  useEffect(() => success && history.push("/"), [success]);

  return (
    <>
      <GuestTopBar />
      <div className="login flex-jc-c flex-ai-c">
        <Card className="login-form">
          <h3 className="login-form__header">Recover Password</h3>
          <NewPasswordForm
            onSubmitForm={onSubmit}
            successMessage={success}
            errorMessage={error}
            pending={pending}
          />
        </Card>
      </div>
    </>
  );
};

export default recoverPassword;
