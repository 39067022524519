import utils from "../../helpers";
import {
  REVERSE_TRANSFER_PENDING,
  REVERSE_TRANSFER_SUCCESS,
  REVERSE_TRANSFER_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const reverseTransferPending = () => ({
  type: REVERSE_TRANSFER_PENDING,
});

export const reverseTransferSuccess = (payload) => ({
  type: REVERSE_TRANSFER_SUCCESS,
  payload,
});

export const reverseTransferFailure = (error) => ({
  type: REVERSE_TRANSFER_FAILURE,
  error,
});

export const reverse = (id) => {
  return (dispatch) => {
    dispatch(reverseTransferPending());
    http()
      .put(`/fundboard_memberships/${id}/reverse_deposit`)
      .then((response) => dispatch(reverseTransferSuccess(response.data)))
      .catch((error) => dispatch(reverseTransferFailure(handleErrors(error))));
  };
};
