// create payment
export const CREATE_PAYMENT_PENDING = "CREATE_PAYMENT_PENDING";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";

// confirm payment
export const CONFIRM_PAYMENT_PENDING = "CONFIRM_PAYMENT_PENDING";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAILURE = "CONFIRM_PAYMENT_FAILURE";

// begin payment

export const BEGIN_PAYMENT_PENDING = "BEGIN_PAYMENT_PENDING";
export const BEGIN_PAYMENT_SUCCESS = "BEGIN_PAYMENT_SUCCESS";
export const BEGIN_PAYMENT_FAILURE = "BEGIN_PAYMENT_FAILURE";

// complete payment
export const COMPLETE_PAYMENT_PENDING = "COMPLETE_PAYMENT_PENDING";
export const COMPLETE_PAYMENT_SUCCESS = "COMPLETE_PAYMENT_SUCCESS";
export const COMPLETE_PAYMENT_FAILURE = "COMPLETE_PAYMENT_FAILURE";

// billing details
export const BILLING_DETAILS_PENDING = "BILLING_DETAILS_PENDING";
export const BILLING_DETAILS_SUCCESS = "BILLING_DETAILS_SUCCESS";
export const BILLING_DETAILS_FAILURE = "BILLING_DETAILS_FAILURE";

// preload payment
export const PAY_THROUGH_PRELOAD_PENDING = "PAY_THROUGH_PRELOAD_PENDING";
export const PAY_THROUGH_PRELOAD_SUCCESS = "PAY_THROUGH_PRELOAD_SUCCESS";
export const PAY_THROUGH_PRELOAD_FAILURE = "PAY_THROUGH_PRELOAD_FAILURE";

// finish transaction
export const COMPLETE_BEGIN = "COMPLETE_BEGIN";
export const COMPLETE_CONFIRM = "COMPLETE_CONFIRM";
export const COMPLETE_PRELOAD = "COMPLETE_PRELOAD";
export const COMPLETE_PAYMENT = "COMPLETE_PAYMENT";

// order through paypal
export const PRELOAD_ORDER_PENDING = "PRELOAD_ORDER_PENDING";
export const PRELOAD_ORDER_SUCCESS = "PRELOAD_ORDER_SUCCESS";
export const PRELOAD_ORDER_FAILURE = "PRELOAD_ORDER_FAILURE";

// foundation gift order through paypal
export const FOUNDATION_GIFT_ORDER_PENDING = "FOUNDATION_GIFT_ORDER_PENDING";
export const FOUNDATION_GIFT_ORDER_SUCCESS = "FOUNDATION_GIFT_ORDER_SUCCESS";
export const FOUNDATION_GIFT_ORDER_FAILURE = "FOUNDATION_GIFT_ORDER_FAILURE";
