import { useLocation } from "react-router-dom";

export const range = (start, end) => {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const currencyToNumber = (number) =>
  number ? Number(number.replace(/[^0-9.-]+/g, "")) : 0;

export const getUrlParams = (str) => {
  const location = useLocation();
  const search = location.search;
  return new URLSearchParams(search).get(str);
};
