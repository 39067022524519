/* eslint-disable no-unused-vars */
import utils from "../../helpers";
import {
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const deleteAccountPending = () => ({
  type: DELETE_ACCOUNT_PENDING,
});

export const deleteAccountFailure = (error) => ({
  type: DELETE_ACCOUNT_FAILURE,
  error,
});

export const deleteAccountSuccess = (payload) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload,
});

export const deleteAccount = (id) => {
  return (dispatch) => {
    dispatch(deleteAccountPending());
    http()
      .put(`/users/${id}/deactivate`)
      .then((response) => {
        dispatch(deleteAccountSuccess(response.data.message));
      })
      .catch((error) => dispatch(deleteAccountFailure(handleErrors(error))));
  };
};
