import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// component
import { Table, Button, Card } from "antd";
import Dashboard from "../index";
import { columns } from "./columns";

// actions
import { getBoardInvites } from "../../../actions/board/getInvites";

// helpers
import { addCart, saveItem } from "../../../helpers/localStorage";

const FundBoardsInvites = () => {
  const history = useHistory();
  const { invites, pending } = useSelector((state) => state?.getInvites);
  const [boardInfo, setBoardInfo] = useState([]);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBoardInvites());
  }, []);

  const handleSubmit = () => {
    addCart({
      inputs: { [total]: 1 },
      total,
      boardInfo,
      type: "invite",
    });
    saveItem("type", "invite");
    history.push("/gift-checkout");
  };

  const rowSelection = {
    onChange: (_, data) => {
      setTotal(data[0]?.amount);
      setBoardInfo({
        fundboard_id: data[0]?.fundboard_id,
      });
    },
  };

  return (
    <Dashboard>
      <Card className="gift-invites" size="small">
        <h3 className="element-header gift-title">FundBoard Invites</h3>
        <Table
          dataSource={invites?.map((d, i) => ({
            ...d,
            key: i,
            name: `${d.fundboard_id}-${d.id}`,
          }))}
          columns={columns}
          pagination={{ pageSize: 5 }}
          loading={pending}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
        />
        <div className="gift-invites-btn">
          <Button
            type="primary"
            disabled={boardInfo.length < 1}
            onClick={handleSubmit}
          >
            Proceed to Checkout
          </Button>
        </div>
      </Card>
    </Dashboard>
  );
};

export default FundBoardsInvites;
