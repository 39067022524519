import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// components
import { Button, Card, Badge } from "antd";

// actions
import { waitingList as waitingListAction } from "../../../actions/waitingList/getWaitingList";

// helpers
import utils from "../../../helpers";

const { currencyToNumber } = utils;

const nearestPosition = (waitingList) => {
  const positions = waitingList
    ?.filter(
      ({ position, fundboard_id }) => position >= 0 && fundboard_id !== 6
    )
    .map(({ position }) => position);

  let leastPosition = positions ? Math.min(...positions) : -1;
  if (leastPosition === Infinity) leastPosition = 0;

  const data = waitingList?.find(({ position }) => position === leastPosition);

  return [leastPosition, data?.gift_in];
};

const Waiting = ({ pending }) => {
  const history = useHistory();
  const handleWaitingList = () => {
    history.push("/waiting-list");
  };

  const { waitingList } = useSelector((state) => state?.waitingList);

  const [position, setPosition] = useState(-1);
  const [board, setBoard] = useState();
  const [num, setNum] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => dispatch(waitingListAction()), []);

  useEffect(() => {
    const [position, board] = nearestPosition(waitingList);
    setPosition(position);
    setBoard(board);

    const count = waitingList?.filter(
      (x) => x.membership_type === "purchase"
    )?.length;
    count
      ? setNum(`You have ${count} active boards`)
      : setNum("You don't have any boards");
  }, [waitingList]);

  return (
    <Badge.Ribbon text={num}>
      <Card style={{ padding: 0 }} className="element-box" loading={pending}>
        <h3 className="label title">Waiting list</h3>
        {position < 0 || !waitingList?.length ? (
          <p className="element-description">
            You are not active on any boards.
          </p>
        ) : (
          <p className="element-description">
            {position === 0
              ? `You are active on $${currencyToNumber(board)}`
              : position === 1
              ? `You are next on $${currencyToNumber(board)}`
              : `You are #${position} on $${currencyToNumber(board)}`}
          </p>
        )}
        <Button
          type="ghost"
          className="btn-outline-primary dashboard-btn"
          onClick={handleWaitingList}
        >
          View Boards
        </Button>
      </Card>
    </Badge.Ribbon>
  );
};

Waiting.propTypes = {
  pending: PropTypes.bool,
};

export default Waiting;
