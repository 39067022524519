import React from "react";

// components
import { Button } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

// helpers
import helpers from "../../../../../helpers";

const transferButton = (
  onTransferToAccount,
  pendingTransfer,
  pendingId,
  data
) => (
  <Button
    onClick={() => onTransferToAccount(data?.id, data?.net_payout)}
    icon={<ArrowUpOutlined />}
    type="primary"
    loading={pendingTransfer && pendingId === data?.id}
  >
    Send To Preload
  </Button>
);

const reverseButton = (
  onReverseToAccount,
  pendingTransfer,
  pendingId,
  data
) => (
  <Button
    onClick={() => onReverseToAccount(data?.id, data?.net_payout)}
    icon={<ArrowDownOutlined />}
    type="primary"
    loading={pendingTransfer && pendingId === data?.id}
  >
    Reverse
  </Button>
);

const columns = (
  onTransferToAccount,
  onReverse,
  pendingTransfer,
  pendingId,
  stat,
  user
) => [
  {
    title: "Fundboard",
    render: (data) => `$${data}`,
    dataIndex: "fundboard",
  },
  {
    title: "Net Payout",
    dataIndex: "net_payout",
  },
  {
    title: "Paid",
    render: (data) => (data?.paid ? "Paid" : "Not Paid"),
  },
  {
    title: "Paid On",
    render: (data) =>
      data?.paid_on && data?.paid
        ? `${helpers.formatDate(data?.paid_on)}`
        : `-`,
  },
  {
    title: "Send To Preload",
    // eslint-disable-next-line
    render: (data) =>
      !data?.paid
        ? transferButton(
            onTransferToAccount,
            pendingTransfer,
            pendingId,
            data,
            stat
          )
        : helpers.currencyToNumber(user?.account_balance) >=
            helpers.currencyToNumber(data?.net_payout) &&
          reverseButton(onReverse, pendingTransfer, pendingId, data, stat),
  },
];

export default columns;
