import utils from "../../helpers";
import {
  GET_ACCOUNTS_PENDING,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const getAccountsPending = () => ({
  type: GET_ACCOUNTS_PENDING,
});

export const getAccountsSuccess = (payload) => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload,
});

export const getAccountsFailure = (error) => ({
  type: GET_ACCOUNTS_FAILURE,
  error,
});

export const getAccounts = () => {
  return (dispatch) => {
    dispatch(getAccountsPending());
    http()
      .get("/users")
      .then((response) => {
        dispatch(getAccountsSuccess(response?.data?.all_users));
      })
      .catch((error) => dispatch(getAccountsFailure(handleErrors(error))));
  };
};
