import React, { useState, useEffect } from "react";

// components
import { Tabs, Card } from "antd";
import Dashboard from "../../Dashboard";
import ChangePassword from "./ChangePassword";
import ProfileForm from "./UserInfo";
import UserProfile from "./UserInfo/profile";

//hooks
import { useAuth } from "../../../hooks/context";

const { TabPane } = Tabs;

const Settings = () => {
  const { user } = useAuth();

  const [userInfo, updateUserInfo] = useState(false);
  const [fhidden, setFhidden] = useState(true);
  const [lhidden, setLhidden] = useState(true);

  useEffect(() => {
    user.fname === null || user.fname === undefined || user.fname === ""
      ? setFhidden(false)
      : setFhidden(true);
    user.lname === null || user.lname === undefined || user.lname === ""
      ? setLhidden(false)
      : setLhidden(true);
  }, [user]);

  return (
    <Dashboard>
      <h3 className="settings-header">Settings</h3>
      <Card className="settings">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Change Password" key="1">
            <ChangePassword />
          </TabPane>
          {userInfo ? (
            <TabPane tab="Your profile" key="2">
              <ProfileForm
                fhidden={fhidden}
                lhidden={lhidden}
                updateUserInfo={updateUserInfo}
                user={user}
              />
            </TabPane>
          ) : (
            <TabPane tab="Your profile" key="2">
              <UserProfile user={user} updateUserInfo={updateUserInfo} />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </Dashboard>
  );
};

export default Settings;
