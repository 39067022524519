import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Input } from "antd";

// stripe
// import {
//   CardNumberElement,
//   CardCvcElement,
//   CardExpiryElement,
// } from "@stripe/react-stripe-js";

const PayForm = ({
  handleSubmit,
  stripe,
  pending,
  completePending,
  errors,
  form,
}) => (
  <div className="payment">
    <Form onFinish={handleSubmit} form={form}>

      <Form.Item name="card">
        <label className="payment-card">
          Card Number:
          {/* <CardNumberElement */}
          {/*   className="payment-form ant-input" */}
          {/*   name="card" */}
          {/*   options={{ */}
          {/*     iconStyle: "solid", */}
          {/*     showIcon: true, */}
          {/*   }} */}
          {/* /> */}
        </label>
      </Form.Item>

      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Name on Card is required!",
          },
        ]}
      >
        <label className="payment-name">
          Name:
          <Input placeholder="Name on Card" name="name" />
        </label>
      </Form.Item>

      <div className="flex flex-column-gap-1 flex-jc-sb">
        <Form.Item name="cvc" style={{ width: "45%" }}>
          <label className="payment-cvc">
            CVC
            {/* <CardCvcElement name="cvc" className="payment-form ant-input" /> */}
          </label>
        </Form.Item>

        <Form.Item name="expiry" style={{ width: "45%" }}>
          <label className="payment-expiry">
            Expiry Date
            {/* <CardExpiryElement */}
            {/*   className="payment-form ant-input" */}
            {/*   name="expiry" */}
            {/* /> */}
          </label>
        </Form.Item>
      </div>
      <Button
        type="primary"
        htmlType="submit"
        disabled={!stripe}
        loading={pending || completePending}
      >
        Save to Account
      </Button>
      <div className="payment-errors">
        {errors && !pending && (
          <Alert message={errors} type="error" closable showIcon />
        )}
      </div>
    </Form>
  </div>
);

PayForm.propTypes = {
  handleSubmit: PropTypes.func,
  stripe: PropTypes.any,
  pending: PropTypes.bool,
  completePending: PropTypes.bool,
  errors: PropTypes.any,
  form: PropTypes.any,
};

export default PayForm;
