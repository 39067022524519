import {
  PAY_THROUGH_PRELOAD_PENDING,
  PAY_THROUGH_PRELOAD_SUCCESS,
  PAY_THROUGH_PRELOAD_FAILURE,
  COMPLETE_PRELOAD,
} from "../../actions/payment/types";

const initialState = {
  preload: null,
  pending: false,
  error: null,
};

const preloadPayPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAY_THROUGH_PRELOAD_PENDING:
      return { pending: true };
    case PAY_THROUGH_PRELOAD_SUCCESS:
      return {
        preload: action.payload,
        pending: false,
      };
    case PAY_THROUGH_PRELOAD_FAILURE:
      return {
        error: action.error,
        pending: false,
      };
    case COMPLETE_PRELOAD:
      return initialState;
    default:
      return state;
  }
};

export default preloadPayPaymentReducer;
