import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// components
import { Table, Button, Form } from "antd";
import FormModal from "./FormModal";
import { success, error as errorModal } from "../../../../../common/Modals";

// actions
import { getBoards } from "../../../../../actions/board/get";
import { inviteBoard } from "../../../../../actions/board/invite";

// hooks
import { useAuth } from "../../../../../hooks/context";

const columns = (setVisible) => [
  {
    title: "Board",
    dataIndex: "gift_in",
    key: "gift_in",
    render: (value) => `$${Number(value)}`,
  },
  {
    title: "Level",
    dataIndex: "level",
    key: "level",
  },
  {
    title: "Full Potential",
    dataIndex: "full_potential",
    key: "full_potential",
    render: (value) => `$${value}`,
  },
  {
    title: "Invite",
    key: "id",
    /* eslint-disable-next-line */
    render: (data) => (
      <Button
        onClick={() => setVisible(data?.id, data?.gift_in)}
        type="primary"
      >
        Invite
      </Button>
    ),
  },
];

const FundBoardList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const history = useHistory();

  const { boards, pending: boardPending } = useSelector(
    (state) => state?.boards
  );
  const { pending, error, invite } = useSelector((state) => state?.inviteBoard);

  const [visible, setVisible] = useState(false);
  const [boardId, setBoardId] = useState();
  const [selectedFundBoard, setSelectedFundBoard] = useState();

  const showModal = () => setVisible(!visible);

  const handleSelectBoard = (id, board) => {
    setVisible(!visible);
    setBoardId(id);
    setSelectedFundBoard(board);
  };

  const handleSubmit = (e) => {
    const data = e?.emails?.map((z) => ({
      email: z.email,
      position: z.position,
      fundboard_id: boardId,
    }));
    dispatch(inviteBoard(data));
  };

  useEffect(
    () => user?.community_id && dispatch(getBoards(user?.community_id)),
    [user]
  );

  useEffect(() => {
    if (error || invite) {
      error && errorModal(error, history);
      invite && success(invite?.message, history);
      form.resetFields();
      setVisible(false);
    }
  }, [pending, error, invite]);

  return (
    <>
      <Table
        columns={columns(handleSelectBoard)}
        dataSource={boards?.filter((data) => !data.active)}
        loading={boardPending}
        pagination={{ pageSize: 5 }}
      />
      <FormModal
        visible={visible}
        showModal={showModal}
        onSubmit={handleSubmit}
        pending={pending}
        errors={error}
        message={invite?.message}
        form={form}
        selectedFundBoard={selectedFundBoard}
      />
    </>
  );
};

FundBoardList.propTypes = {
  boards: PropTypes.array,
};

export default FundBoardList;
