import utils from "../../helpers";
import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_FAILURE } from "./types";

const { http, handleErrors } = utils;

export const loginPending = () => ({
  type: LOGIN_PENDING,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error,
});

export const login = (user) => {
  return (dispatch) => {
    dispatch(loginPending());
    http()
      .post("/auth/sign_in", user)
      .then((response) =>
        dispatch(
          loginSuccess({
            data: response?.data,
            token: response?.headers["access-token"],
            token_type: response?.headers["token-type"],
            uid: response?.headers["uid"],
            expiry: response?.headers["expiry"],
            client: response?.headers["client"],
          })
        )
      )
      .catch((error) => dispatch(loginFailure(handleErrors(error))));
  };
};
