import utils from "../../helpers";
import {
  PAY_MEMBERSHIPS_PENDING,
  PAY_MEMBERSHIPS_SUCCESS,
  PAY_MEMBERSHIPS_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

export const payMembershipsPending = () => ({
  type: PAY_MEMBERSHIPS_PENDING,
});

export const payMembershipsSuccess = (payload) => ({
  type: PAY_MEMBERSHIPS_SUCCESS,
  payload,
});

export const payMembershipsFailure = (error) => ({
  type: PAY_MEMBERSHIPS_FAILURE,
  error,
});

export const payMemberships = (memberships) => {
  return (dispatch) => {
    dispatch(payMembershipsPending());
    http()
      .put(
        `/fundboard_memberships/mark_many_as_paid?membership_ids[]=[${memberships}]`
      )
      .then((response) =>
        dispatch(payMembershipsSuccess(response.data))
      )
      .catch((error) => dispatch(payMembershipsFailure(handleErrors(error))));
  };
};
