import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Input } from "antd";

const NewPasswordForm = ({
  onSubmitForm,
  pending,
  successMessage,
  errorMessage,
}) => {
  const [form] = Form.useForm();
  return (
    <Form onFinish={onSubmitForm} form={form}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Password is required!",
          },
          {
            validator: (_, value) =>
              value?.length >= 6
                ? Promise.resolve()
                : Promise.reject(
                    "Password should be greater than or equal to 6 characters!"
                  ),
          },
        ]}
      >
        <label>
          Password:
          <Input.Password name="password" placeholder="******" />
        </label>
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: "Confirm Password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <label>
          Confirm Password:
          <Input.Password name="confirmPassword" placeholder="******" />
        </label>
      </Form.Item>
      <Form.Item>
        <div className="flex flex-jc-fe">
          <Button type="primary" htmlType="submit" loading={pending}>
            Change Password
          </Button>
        </div>
      </Form.Item>
      {errorMessage && (
        <Alert message={errorMessage} type="error" closable showIcon />
      )}
      {successMessage && (
        <Alert message={successMessage} type="success" closable showIcon />
      )}
    </Form>
  );
};

NewPasswordForm.propTypes = {
  onSubmitForm: PropTypes.func,
  pending: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default NewPasswordForm;
