export const getColor = (text) => {
    let color;
    switch (text) {
      case 'On hold':
        color = "red";
        break;
      case 'Promoted':
        color = "Green";
        break;
      case 'Active':
        color = "gold";
         break;
      case 'Next':
        color = "teal";
        break;
      default:
        color= "blue";
        break;
    }
    return color;
    
  }; 