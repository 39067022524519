import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

const paypal = window.paypal;
const PayPalButton = paypal?.Buttons.driver("react", { React, ReactDOM });

const PayPalCheckout = ({
  amount,
  onError,
  onCancel,
  handleApprove,
  handlePending,
  user,
}) => {
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
            currency: "USD",
          },
          custom_id: user?.email,
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    const { id } = await actions.order.capture();
    handleApprove(id);
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onCancel={(data) => onCancel(data)}
      onError={(error) => onError(error)}
      onClick={handlePending}
    />
  );
};
PayPalCheckout.propTypes = {
  amount: PropTypes.number,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  handleApprove: PropTypes.func,
  handlePending: PropTypes.func,
  user: PropTypes.object,
};
export default PayPalCheckout;
