import React from "react";
import PropTypes from "prop-types";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Input, Form, Button, Alert } from "antd";

const ChangePasswordForm = ({
  handleSubmit,
  form,
  pending,
  errors,
  success,
}) => (
  <Form onFinish={handleSubmit} form={form}>
    <Form.Item
      name="password"
      rules={[
        {
          validator: (_, value) =>
            value?.length >= 6
              ? Promise.resolve()
              : Promise.reject(
                  "Password should be greater than or equal to 6 characters!"
                ),
        },
        {
          required: true,
          message: "Please input your password!",
        },
      ]}
    >
      <label>
        Password:
        <Input.Password name="password" placeholder="******" />
      </label>
    </Form.Item>
    <Form.Item
      name="confirmPassword"
      dependencies={['password']}
      rules={[
        {
          required: true,
          message: "Please confirm your password!",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              "The two passwords that you entered do not match!"
            );
          },
        }),
      ]}
    >
      <label>
        Confirm Password:
        <Input.Password name="confirmPassword" placeholder="******" />
      </label>
    </Form.Item>
    <div className="flex-end">
      <Form.Item>
        <Button
          type="primary"
          className="float-right"
          htmlType="submit"
          icon={<CheckCircleOutlined />}
          loading={pending}
        >
          Change Password
        </Button>
      </Form.Item>
      <div className="settings-change-password-errors">
        {errors && <Alert message={errors} type="error" showIcon closable />}
        {success && (
          <Alert message={success} type="success" showIcon closable />
        )}
      </div>
    </div>
  </Form>
);

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  errors: PropTypes.any,
  pending: PropTypes.bool,
  success: PropTypes.any,
};

export default ChangePasswordForm;
