import React from "react";

// components
import { Input } from "antd";
import Links from "../TopBar/Links";
import Logo from "../../../common/Logo";

const SideBar = () => (
  <aside className="hide-for-mobile sidebar">
    <div className="sidebar-link-wrapper">
      <Logo />
      <Input.Search
        placeholder="input search text..."
        className="search-input"
        allowClear
      />
      <Links classes="sidebar-link" />
    </div>
  </aside>
);

export default SideBar;
