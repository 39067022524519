import React from "react";

// component
import { Select, Form } from "antd";

const selectData = () =>
  [0, 1, 2, 3, 4, 5].map((item) => ({ value: item, name: item }));

const columns = (handleInputChange, total, inputs, count) => [
  {
    title: "Fundboard",
    dataIndex: "gift_in",
    key: "gift_in",
    render: (item) => `$${parseInt(item)}`,
  },
  {
    title: "Quantity",
    key: "gift_in",
    /* eslint-disable-next-line */
    render: (data) => (
      <Form.Item
        validateStatus={count > 5 && "error"}
        help={count > 5 && "Total Quantity should be less than or equal to 5"}
      >
        <Select
          options={selectData()}
          placeholder={0}
          className="columns-select"
          onChange={(evt) => handleInputChange(evt, data?.gift_in)}
          value={inputs && inputs[data?.gift_in]}
          name={data?.gift_in}
          allowClear
        />
      </Form.Item>
    ),
  },
  {
    title: "Total",
    key: "gift_in",
    render: (data) => (inputs && data?.gift_in * inputs[data?.gift_in]) || 0,
  },
];

export default columns;
