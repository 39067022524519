import React from "react";
import PropTypes from "prop-types";

// components
import { PlusCircleFilled } from '@ant-design/icons';
import { Form, Input, InputNumber, Space } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const InviteForm = ({ form }) => {
  return (
    <Form form={form} name="dynamic_form_nest_item" preserve={false}>
      <Form.List name="emails">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  name={[field.name, "position"]}
                  fieldKey={[field.fieldKey, "position"]}
                  rules={[{ required: true, message: "Position is required!" }]}
                >
                  <InputNumber min={1} placeholder="pos" />
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, "email"]}
                  fieldKey={[field.fieldKey, "email"]}
                  rules={[
                    { required: true, message: "Email is Required!" },
                    { type: "email", message: "Valid email is required!" },
                  ]}
                >
                  <Input placeholder="email" />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(field.name)}
                  style={{ color: "#ff4d4f" }}
                />
              </Space>
            ))}
            <Form.Item>
              <PlusCircleFilled
                style={{ color: "#1890ff", fontSize: "24px" }}
                onClick={add}
              />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

InviteForm.propTypes = {
  form: PropTypes.any,
};

export default InviteForm;
