import {
  GET_ACCOUNTS_PENDING,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
} from "../../actions/accounts/types";

const initialState = {
  accounts: [],
  pending: false,
  error: null,
};

const getAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_PENDING:
      return { pending: true };
    case GET_ACCOUNTS_SUCCESS:
      return {
        accounts: action.payload,
        pending: false,
        error: null,
      };
    case GET_ACCOUNTS_FAILURE:
      return {
        error: action.error,
        pending: false,
        accounts: [],
      };
    default:
      return state;
  }
};

export default getAccountsReducer;
