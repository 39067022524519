import {
  REPORT_ISSUE_PENDING,
  REPORT_ISSUE_SUCCESS,
  REPORT_ISSUE_FAILURE,
} from "./types";

import axios from "axios";

const reportIssuePending = () => ({
  type: REPORT_ISSUE_PENDING,
});

const reportIssueSuccess = (payload) => ({
  type: REPORT_ISSUE_SUCCESS,
  payload,
});

const reportIssueError = (error) => ({
  type: REPORT_ISSUE_FAILURE,
  error,
});

export const reportIssue = ({ issue, category, email }) => {
  const formSpreeId = process.env.REACT_APP_FORMSPREE_ID;
  return (dispatch) => {
    dispatch(reportIssuePending());
    axios({
      url: `https://formspree.io/f/${formSpreeId}`,
      method: "post",
      headers: null,
      data: {
        email,
        issue,
        category,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(reportIssueSuccess(true));
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        dispatch(
          reportIssueError("Something went wrong.Please try again later")
        );
      });
  };
};
