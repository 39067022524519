import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/context";

const Public = ({ component: Component, ...rest }) => {
  const { getToken } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        !getToken() ? <Component {...props} /> : <Redirect to="/home" />
      }
    />
  );
};

Public.propTypes = {
  component: PropTypes.any,
};

export default Public;
