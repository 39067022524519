import utils from "../../helpers";
import {
  BEGIN_PAYMENT_PENDING,
  BEGIN_PAYMENT_SUCCESS,
  BEGIN_PAYMENT_FAILURE,
} from "./types";

const { http, handleErrors } = utils;

const beginPaymentPending = () => ({
  type: BEGIN_PAYMENT_PENDING,
});

const beginPaymentSuccess = (payload) => ({
  type: BEGIN_PAYMENT_SUCCESS,
  payload,
});

const beginPaymentError = (error) => ({
  type: BEGIN_PAYMENT_FAILURE,
  error,
});

export const beginPayment = (boards, type, rest) => {
  const payload = {
    line_item_params: boards,
    order_params: { gift_type: type, source: "CC" },
  };

  if (type === "preload") {
    payload.preload_params = { amount: rest[0] };
    payload.order_params = { gift_type: type, source: "USER_ACCOUNT" };
  }

  if (type === "normal-invite") {
    payload.order_params = { gift_type: "preload", source: "USER_ACCOUNT" };
    payload.invited = true;
  }
  return (dispatch) => {
    dispatch(beginPaymentPending());
    http()
      .post("/orders", payload)
      .then((response) => dispatch(beginPaymentSuccess(response?.data)))
      .catch((error) => dispatch(beginPaymentError(handleErrors(error))));
  };
};
