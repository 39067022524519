export const columns = [
  {
    title: "Fundboard",
    dataIndex: "amount",
    render: (item) => `$${parseInt(item)} Fundboard`,
  },
  {
    title: "Position",
    dataIndex: "position",
  },
];
