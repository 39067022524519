import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import propTypes from "prop-types";

// components
import { SyncOutlined } from "@ant-design/icons";
import { Table, Button, Card } from "antd";
import Dashboard from "../../../../Dashboard";
import columns from "./columns";
import { success, confirm } from "../../../../../common/Modals";

// actions
import { transferToAccount } from "../../../../../actions/board/transferToAccount";
import { reverse } from "../../../../../actions/board/reverseTransfer";
import { getStat } from "../../../../../actions/stats";

// hooks
import { useAuth } from "../../../../../hooks/context";

const Payouts = ({ refresh, pPending, pending, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuth();

  const [selectedId, setSelectedId] = useState();

  const { stat } = useSelector((state) => state?.stat);
  const { data: transfer, pending: pendingTransfer } = useSelector(
    (state) => state?.transferToAccount
  );

  const { data: reverseTransfer, pending: pendingReverse } = useSelector(
    (state) => state?.reverse
  );


  const onTransferToAccount = (id, amount) => {
    confirm(
      <p>
        Are you sure you want to transfer <strong>${amount}</strong> to your
        account?
      </p>,
      [
        { 1: setSelectedId, 2: [id] },
        { 1: dispatch, 2: transferToAccount, 3: [id] },
      ]
    );
  };

  const onReverse = (id, amount) => {
    confirm(
      <p>
        Are you sure you want to withdraw <strong>${amount}</strong> from your
        account?
      </p>,
      [
        { 1: setSelectedId, 2: [id] },
        { 1: dispatch, 2: reverse, 3: [id] },
      ]
    );
  };

  useEffect(() => {
    if (transfer) {
      success("Transfer to Preload successful", history);
    }
  }, [transfer]);

  useEffect(() => {
    if (reverseTransfer) {
      success("Transaction Successful", history);
    }
  }, [reverseTransfer]);

  useEffect(() => dispatch(getStat()), []);

  return (
    <Dashboard>
      <h3 className="admin-payouts-title">Net Payouts</h3>
      <Card className="admin-payouts">
        <div className="flex flex-jc-fe flex-row-gap-2">
          <Button
            type="dashed"
            icon={<SyncOutlined />}
            onClick={refresh}
            loading={pending || pPending}
          >
            Refresh
          </Button>
        </div>
        <br />
        <Table
          rowKey={(d) => d.id}
          columns={columns(
            onTransferToAccount,
            onReverse,
            pendingTransfer || pendingReverse,
            selectedId,
            stat,
            user
          )}
          dataSource={data?.filter((m) => m.left_on !== null)}
          loading={pending}
          className="admin-payouts"
          pagination={{ pageSize: 5 }}
          showSorterTooltip
        />
      </Card>
    </Dashboard>
  );
};

Payouts.propTypes = {
  refresh: propTypes.func,
  pPending: propTypes.bool,
  pending: propTypes.bool,
  data: propTypes.array
};

export default Payouts;
